import { Card } from "react-bootstrap";
import styles from "../styles/OverideStyles.module.css";
import { Toaster } from "react-hot-toast";
import { Alert, Button, Tab, Tabs } from "react-bootstrap";

function CardLayoutCommander(props) {
    return (<>

        <div className={`${styles.mbody} "bg-white"`}>
            <div className={styles.containerlogin}>
                <div className={styles.forCommander}>
                    <Toaster />
                    <Card className="rounded shadow" style={props.baseStyle}>
                        {/* <Tabs defaultActiveKey="pool"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            justify
                            fill>
                            <Tab eventKey="pool" title="Car Pool">
                                <Card.Header className="bg-white" style={props.headStyle}>{props.header}</Card.Header>
                                <Card.Body style={props.bodyStyle}>{props.body}</Card.Body>
                                {props.footer && <Card.Footer className="bg-white text-center" style={{ borderTop: "0px" }}>{props.footer}</Card.Footer>}
                            </Tab>
                            <Tab eventKey="delivery" title="Deliver">
                                <Card.Header className="bg-white" style={props.headStyle}>{props.headerDeliver}</Card.Header>
                                <Card.Body style={props.bodyStyle}>{props.bodyDeliver}</Card.Body>
                                {props.footer && <Card.Footer className="bg-white text-center" style={{ borderTop: "0px" }}>{props.footer}</Card.Footer>}
                            </Tab>
                        </Tabs> */}
                        <Card.Header className="bg-white" style={props.headStyle}>{props.header}</Card.Header>
                        <Card.Body style={props.bodyStyle}>{props.body}</Card.Body>
                        {props.footer && <Card.Footer className="bg-white text-center" style={{ borderTop: "0px" }}>{props.footer}</Card.Footer>}
                    </Card>
                </div>
            </div>
        </div >
    </>
    );
}

export default CardLayoutCommander;