import { createBrowserRouter, Navigate, useLoaderData } from "react-router-dom";

// Public pages
import Terms from "./pages/public/Terms";
import AboutUs from "./pages/public/AboutUs";
import Contact from "./pages/public/Contact";
import Privacy from "./pages/public/Privacy";

// Private pages
import Home from "./pages/public/Home";
import Login, {
  action as loginAction,
  loader as loginLoader,
} from "./pages/public/Login";
import Signup from "./pages/public/Signup";
import Account from "./pages/users/Account";
import ForgotPassword from "./pages/public/ForgotPassword";
import ResetPassword from "./pages/public/ResetPassword";
import ResendVfyPhone from "./pages/public/ResendVfyPhone";
import Commander from "./pages/users/Commander";
import Pools from "./pages/users/Pools";
import History from "./pages/users/History";
import Support from "./pages/users/Support";
import PoolDetails from "./pages/users/PoolDetails";
import CreatePool from "./pages/users/CreatePool";
import Notifications from "./pages/users/Notifications";
import Wallet from "./pages/users/Wallet";

import * as config from "./config";
import * as jose from "jose";
import { socket } from "./socket";

// Sidebar
import SideBar from "./components/SideBar";
import Practice from "./components/Practice";
import UnAuthNavBar from "./components/UnAuthNavBar";

const routers = createBrowserRouter([
  {
    path: "/",
    element:<PublicRoute><UnAuthNavBar/></PublicRoute>,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/privacy",
        element: <Privacy />,
      },
      {
        path: "/terms",
        element: <Terms />,
      },
      // {
      //   path: "/about",
      //   element: <AboutUs />,
      // },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/signup",
        element: <Signup />,
      },
      {
        path: "/forgot",
        element: <ForgotPassword />,
      },
      {
        path: "/forgot/password/verify/:hash",
        element: <ResetPassword />,
      },
      {
        path: "/resend",
        element: <ResendVfyPhone />,
      },
      {
        path: "/logout",
        loader: logout,
        element: <Navigate to="/login" />,
        errorElement: <Navigate to="/login" />,
      },
    ],
  },
  {
    path: "/app",
    element: <PrivateRoute><SideBar /></PrivateRoute>,
    errorElement: <Navigate to="/login" />,
    children: [
      {
        path: "history",
        element: <History />,
      },
      {
        path: "practice",
        element: <Practice />,
      },
      {
        path: "pools",
        children:[
            {
                index:true,
                element:<Pools/>
            },
            {
                path:":poolId",
                element:<PoolDetails/>
            },
            {
                path:"create",
                element:<CreatePool/>
            }
        ]
      },
      {
        path: "wallet",
        element: <Wallet />,
      },
      {
        path: "notifications",
        element: <Notifications />,
      },
      {
        path: "commander",
        element: <Commander />,
      },
      {
        path: "account",
        element: <Account />,
      },
      {
        path: "support",
        element: <Support />,
      },
    ],
  },
]);

function isAuthenticated() {
  const isAuthenticated = localStorage.getItem("token");
  if (isAuthenticated !== null) {
    return true;
  }
  else {
    return false;
  }
}

function PrivateRoute({ children }) {
  const auth = isAuthenticated();
  return auth == true ? children : <Navigate to="/login" />;
}


function PublicRoute({ children }) {
  const auth = isAuthenticated();
  return auth == true ? (
    <Navigate to="/app/pools" />
  ) : (
    children
  );
}

async function logout() {
  // const navigate = useNavigate();
  const getToken = localStorage.getItem("token");
  if (getToken !== null) {
    socket.emit("removeSocket");
    localStorage.removeItem("token");
    // navigate("/login");
    socket.disconnect();
    return true;
  } else {
    throw new Error("Invalid");
  }
}
export default routers;
