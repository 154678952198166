import styles from "../styles/OverideStyles.module.css";

function FormLayout(props) {
    return (<>
        <div className={`${styles.mbody} ${props.light ? "bg-white": "bg-dark"}`}>
            <div className={styles.containerlogin}>
                <div className={styles.wraplogin}>
                    {props.children}
                </div>
            </div>
        </div>
    </>
    );
}

export default FormLayout;