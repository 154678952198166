import { Card } from "react-bootstrap";
import styles from "../styles/OverideStyles.module.css";
import { Toaster, toast } from "react-hot-toast";
import { Typography } from "@mui/material";
import { Form } from "react-bootstrap";

function CardLayout({title,form,submitHandler,customTitle}) {
  return (
    <>
      {/* <div className={`${styles.b1}`}>
        <div className={`${styles.b2}`}>
          <div className={`container ${styles.b2}`}>
            <div className="card mb-3 shadow col-sm-12 col-md-8 col-lg-8">
              <div
                className="card-header"
                style={{ background: "white", marginTop: "8px" }}
              >
                <h3>{props.header}</h3>
              </div>
              <div className="card-body mb-3">
                <Card
                  style={props.mystyles ? props.mystyles : { border: "none" }}
                >
                  <Card.Body>{props.children}</Card.Body>
                  {props.footer && <Card.Footer>{props.footer}</Card.Footer>}
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div
          className="p-3 shadow-lg m-auto"
          style={{
            width: "auto",
            minWidth: "250px",
            maxWidth: "400px",
            backgroundColor: "#111315",
            borderRadius: "10px",
          }}
        >
          <Form
            className="mb-3"
            onSubmit={submitHandler}
            encType="multipart/form-data"
          >
            <Toaster />
            {title && <Typography className="mb-3" variant="h5" color={"white"} fontWeight={"bolder"}>
              {title}
            </Typography>}
            {customTitle && customTitle}
            {form}
          </Form>
        </div>
    </>
  );
}

export default CardLayout;
