
import { Button, Card, Form, Spinner, Table } from "react-bootstrap";
import { TextField, Typography } from "@mui/material";
import { Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { configuration } from "../../apis/config";
import axios from "axios";
import { Alert } from "@mui/material";
import PoolCard from "../../components/PoolCard";
import { Toaster, toast } from "react-hot-toast";
import CardLayout from "../../components/CardLayout";
import fromIcon from "../../images/from.svg"
import toIcon from "../../images/to.svg"
import separatorIcon from "../../images/separator.svg"
import store from "../../store/store"
import Loader from "../../components/Loader";

function History() {
    const [pools, setPools] = useState([]);
    const [isReady, setIsReady] = useState(false);

    const getToken = localStorage.getItem("token");
    useEffect(() => {
        axios({
            method: configuration.urls.getPoolsHistoryMethod,
            url: configuration.urls.getPoolsHistory,
            headers: {
                "Authorization": `Bearer ${getToken}`
            }
        })
            .then((resp) => {
                setPools(resp.data.data);
                setIsReady(true);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
                setIsReady(true);
            })
    }, []);

    function fetchAndSetHistory() {

    }

    if (isReady) {
        return (
            <>
                <Toaster />
                <div style={{ height: "100%", width: "100%", alignContent: "center" }}>
                    <div
                        className="p-3 shadow-lg m-auto"
                        style={{
                            width: "auto",
                            minWidth: "250px",
                            maxWidth: "400px",
                            backgroundColor: "#111315",
                            borderRadius: "10px",
                        }}
                    >
                        <Typography className="mb-3" variant="h5" color={"white"} fontWeight={"bolder"}>Ride History</Typography>
                        <Stack>
                            {pools.length == 0 &&
                                <Alert style={{ fontWeight: "bold" }} variant="filled" severity="warning" >No rides yet!</Alert>
                            }
                            {pools.length > 0 && pools.map((item, index) => {

                            })
                            }
                        </Stack>
                    </div>
                </div>
            </>
        );
    }
    else {
        return <Loader />
    }
}

export default History;