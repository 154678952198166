import { useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import styles from "../../styles/OverideStyles.module.css";
import axios from "axios";
import { Form, Button } from "react-bootstrap";
import { Alert, Typography } from "@mui/material";

import { configuration } from "../../apis/config";
import InvalidLink from "../../components/InvalidLink";
import Logo from "../../components/logo";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import config from "../../config";
import FormLayout from "../../components/FormLayout";

const CryptoJS = require("crypto-js");

function ResetPassword() {
  const nav = useNavigate();
  const wholeUrl = useLocation();
  const hash = wholeUrl.pathname.split("/").pop();
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordTemp, setNewPasswordTemp] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [disable, setDisable] = useState(true);
  const [validHash, setValidHash] = useState(false);

  function submitHandler(event) {
    event.preventDefault();
    const newEncrypted = String(
      CryptoJS.AES.encrypt(newPassword, config.encKey)
    );
    const confirmEncrypted = String(
      CryptoJS.AES.encrypt(repeatPassword, config.encKey)
    );
    axios({
      method: configuration.urls.updatePasswordMethod,
      url: configuration.urls.updatePassword,
      data: {
        new: newEncrypted,
        confirm: confirmEncrypted,
        hash: hash,
      },
    })
      .then((resp) => {
        toast.success("Password Changed!");
        setTimeout(() => {
          nav("/login");
        }, 1500);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }

  useEffect(() => {
    axios({
      method: configuration.urls.verifyHashMethod,
      url: configuration.urls.verifyHash,
      data: {
        hash: hash,
      },
    })
      .then((resp) => {
        setValidHash(true);
      })
      .catch((err) => {
        setTimeout(() => {
          nav("/login");
        }, 1500);
      });
  }, [])

  useEffect(() => {
    if (newPassword == repeatPassword) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [repeatPassword]);
  if (validHash) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          alignContent: "center",
        }}
        className={`${styles.loginBg}`}
      >
        <div
          style={{
            backgroundColor: "#171a1d",
            borderRadius: "10px",
            width: "auto",
            maxWidth: "350px",
            minWidth: "250px",
            margin: "auto",
          }}
          className="shadow"
        ><Toaster/>
          <Form className={"p-4"} onSubmit={submitHandler}>
            <div className="mb-3">
              <Typography className="text-white" variant="h4" fontWeight={"bolder"}>Reset Password</Typography>
            </div>

            <Form.Group controlId="password" className="mb-3">
              <Form.Label className="col-form-label text-white">New Password</Form.Label>
              <Form.Control
                className={styles.formfield}
                size="sm"
                name="password"
                id="password"
                type="password"
                placeholder="New password"
                onChange={(e) => {
                  setNewPasswordTemp(e.target.value);
                }}
                onBlur={(e) => {
                  setNewPassword(e.target.value);
                }}
              />
              {newPasswordTemp.length <= 7 && newPasswordTemp.length > 0 && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Password should be atleast 8 characters!
                </div>
              )}
            </Form.Group>
            <Form.Group controlId="passwordconfirm" className="mb-3">
              <Form.Label className="col-form-label text-white">Confirm Password</Form.Label>
              <Form.Control
                className={styles.formfield}
                size="sm"
                name="passwordconfirm"
                id="passwordconfirm"
                type="password"
                placeholder="Confirm password"
                onChange={(e) => {
                  setRepeatPassword(e.target.value);
                }}
              />
              {newPassword !== repeatPassword && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Password's didn't match!
                </div>
              )}
            </Form.Group>

            <Button
              size="sm"
              type="submit"
              className={`btn btn-warning ${styles.loginbtn}`}
            >
              Change
            </Button>
          </Form>
        </div>
      </div>
    );
  }
  else {
    return <Alert variant="standard" color="error" style={{ width: "300px", margin: "auto" }}>Invalid link or Link expired!</Alert>
  }
}

export default ResetPassword;
