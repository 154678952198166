
import { useState } from "react";
import { Typography } from "@mui/material";
import styles from "../../styles/OverideStyles.module.css";
import request from "../../utils/request";
import CardLayout from "../../components/CardLayout";
import { Form, Button } from "react-bootstrap";
function Support() {
  const [sType, setSType] = useState(null);
  const [cat, setCat] = useState(null);

  return (<>
    <div style={{ height: "100%", width: "100%", alignContent: "center" }}>
      <CardLayout title={"Support"}
        form={<>
          <Form.Group className="mb-3">
            <Form.Select className={`${styles.formfield}`} onChange={(e) => { setSType(e.target.value) }} required size="sm">
              <option>Select</option>
              <option value="custom">Write to us</option>
              <option value="email">Mail us</option>
              <option value="whatsapp">Whatsapp us</option>
            </Form.Select>
          </Form.Group>
          {sType && sType == "custom" &&
            <>
              <Form.Group className="mb-3">
                <Form.Select className={`${styles.formfield}`} required size="sm">
                  <option>Select Category</option>
                  <option value="payment">Payment</option>
                  <option value="bug">Bug/Issue</option>
                  <option value="feature">Feature Request</option>
                  <option value="other">Other</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  className={`${styles.formfield}`}
                  name="name"
                  id="name"
                  size="sm"
                  placeholder="Tell us what happend"
                  as={"textarea"}
                  rows={3}
                />
              </Form.Group>
              <div className="text-center">
                <Button type="submit" size="sm" style={{ backgroundColor: "#ffc107", color: "black", borderColor: "#ffc107" }}>Submit</Button>
              </div>
            </>
          }
          {sType && sType == "whatsapp" &&
            <Typography style={{ color: "gray" }}>You can whatsapp us on 8008161484</Typography>
          }
          {sType && sType == "email" &&
            <Typography style={{ color: "gray" }}>You can mail us on admin@commute.network</Typography>
          }
        </>}
      // submitHandler={updateName}
      />
    </div>
  </>)
}

export default Support;