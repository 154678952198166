import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Button, Card, Form } from "react-bootstrap";
import { TextField, Typography } from "@mui/material";
import { Stack } from "@mui/material";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { useEffect, useRef, useState } from "react";
import { configuration } from "../../apis/config";
import { Alert } from "@mui/material";
import PoolCard from "../../components/PoolCard";
import { Toaster, toast } from "react-hot-toast";
import styles from "../../styles/OverideStyles.module.css";
import bgImage from "../../images/bg.png";
import request from "../../utils/request";

function Pools() {
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const inputFromRef = useRef();
  const inputToRef = useRef();
  const [pools, setPools] = useState([]);

  const handlePlaceChangedFrom = async () => {
    const [place] = await inputFromRef.current.getPlaces();
    if (place) {
      setFrom(place);
    }
  };

  const handlePlaceChangedTo = () => {
    const [place] = inputToRef.current.getPlaces();
    if (place) {
      setTo(place);
    }
  };

  useEffect(() => {
    request("POST", configuration.urls.getPools)
      .then((resp) => {
        setPools(resp.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }, []);

  function searchHandler() {
    request("POST", configuration.urls.getPoolsByQuery, {
      from: from ? from.name : "",
      to: to ? to.name : "",
    })
      .then((resp) => {
        setPools(resp.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }
  return (
    <>
      <Toaster />
      <div style={{ backgroundImage: `url(${bgImage})`, height: "100%", width: "100%", backgroundSize: "contain", backgroundRepeat: "repeat" }}>
        <Grid container spacing={2} justifyContent={"center"}>
          <Grid xs={12} sm={12} md={12} lg={5} xl={5}>
            <Card className="rounded shadow" style={{ backgroundColor: "#1a1e21" }}>
              <Card.Body>
                <Stack>
                  <LoadScript
                    googleMapsApiKey={"AIzaSyCe_csGuwZcY7eUjCwwyoHzaVsduXt6pF0"}
                    libraries={["places"]}
                    region="India"
                  >
                    <Typography className="mb-3 text-white" variant="h6" fontWeight={"bolder"}>Search Here</Typography>
                    <StandaloneSearchBox
                      onLoad={(ref) => (inputFromRef.current = ref)}
                      onPlacesChanged={handlePlaceChangedFrom}
                    >
                      <TextField sx={{ input: { color: 'white' } }} InputProps={{ style: { backgroundColor: "#212529" } }} InputLabelProps={{ style: { color: "white" } }} className={`mb-3`} color="primary" size="small" label="From" placeholder="" fullWidth />
                    </StandaloneSearchBox>
                    <StandaloneSearchBox
                      onLoad={(ref) => (inputToRef.current = ref)}
                      onPlacesChanged={handlePlaceChangedTo}
                    >
                      <TextField sx={{ input: { color: 'white' } }} InputProps={{ style: { backgroundColor: "#212529" } }} InputLabelProps={{ style: { color: "white" } }} className="mb-3" size="small" label="To" placeholder="" fullWidth />
                    </StandaloneSearchBox>
                  </LoadScript>
                  <Button size="sm" className="btn-warning" onClick={searchHandler}>
                    Search
                  </Button>
                </Stack>
              </Card.Body>
            </Card>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
            <Stack spacing={4} className="p-1" height={"100%"} justifyContent={"center"}>
              {pools.length != 0 &&
                pools.map((p) => {
                  return <PoolCard data={p} />;
                })}
              {pools.length == 0 && (
                <Alert variant="standard" severity={"warning"}>Sorry no pools in this route!</Alert>
              )}
            </Stack>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Pools;
