
import { InputGroup, Modal, Spinner, Stack } from "react-bootstrap";
import { Form } from 'react-bootstrap';
import { useEffect, useState } from "react";
import axios from "axios";
import { configuration } from "../../apis/config";
import { Button, Table } from "react-bootstrap";
import request from "../../utils/request";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Alert, Chip, Typography } from "@mui/material";
import styles from "../../styles/OverideStyles.module.css";
import SendIcon from '@mui/icons-material/Send';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallMadeIcon from '@mui/icons-material/CallMade';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AddIcon from '@mui/icons-material/Add';
import toast, { Toaster } from "react-hot-toast";
import config from "../../config";
import Loader from "../../components/Loader";

function Wallet() {
  const [wallet, setWallet] = useState(null);
  const [history, setHistory] = useState([]);
  const [show, setShow] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [showTransfer, setShowTransfer] = useState(false);
  const [transferAmount, setTransferAmount] = useState(0);
  const [amountEntered, setAmountEntered] = useState(0);
  const [transferTo, setTransferTo] = useState(null);
  const [showWithdraw, setShowWithdraw] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    fetchAndSetWallet();
  }, []);

  function fetchAndSetWallet() {
    request("POST", configuration.urls.getWallet)
      .then((resp) => {
        setWallet(resp.data);
        setHistory(resp.history);
        setIsReady(true);
      })
      .catch((err) => {
        console.log(err);
        setIsReady(true);
      });
  }

  function handleAddBalance(e) {
    request("POST", configuration.urls.addBalance, { amount: Number(amountEntered) * 100 })
      .then((resp) => {
        var options = {
          key: config.rKey, // Enter the Key ID generated from the Dashboard
          amount: resp.data.amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: "INR",
          name: "Commute Network", //your business name
          description: "Wallet Top-Up",
          image: "https://firebasestorage.googleapis.com/v0/b/commute-92221.appspot.com/o/icon.png?alt=media&token=f9d3ae2b-7a91-4f87-b566-1b531fb1bbba&_gl=1*10m54jr*_ga*MTAyNzQyODg0LjE2ODkxNTQxMzQ.*_ga_CW55HF8NVT*MTY5NzE2NzgzOC42NC4xLjE2OTcxNjc4NzkuMTkuMC4w`",
          order_id: resp.data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          callback_url: configuration.urls.addBalanceRedirect,
          prefill: {
            name: resp.data.name, //your customer's name
            email: "admin@commute.network",
            contact: resp.data.phone,
          },
          retry: false,
          hidden: { contact: true, email: true },
          theme: {
            color: "#ffc107",
          },
        };
        var rzp1 = new Razorpay(options);
        rzp1.open();
        e.preventDefault();

      })
      .catch((err) => {
        console.log(err);
      });
  }

  function setProperties(titleColor, des, iconColor, icon, title, amount, status) {
    var properties = {};
    properties["titleColor"] = titleColor;
    properties["description"] = des;
    properties["iconColor"] = iconColor;
    properties["icon"] = icon;
    properties["title"] = title;
    properties["amount"] = amount;
    properties["status"] = status;
    return properties;
  }

  function transferHandler() {
    request(
      configuration.urls.walletTransferMethod,
      configuration.urls.walletTransfer,
      { phone: transferTo, amount: Number(transferAmount) },
    )
      .then((resp) => {

      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }

  function withdrawHandler() {

  }

  if (isReady) {
    return (<>
      <Toaster />
      <Grid2 container spacing={2} p={2}>
        <Grid2 xs={12} sm={12} md={12} lg={4} xl={4}>
          <div className={`p-4 ${styles.atm}`} style={{ backgroundColor: "#ffc107", borderRadius: "10px" }}>
            <Typography>Balance</Typography>
            <Stack direction="horizontal" style={{ justifyContent: "space-between" }}>
              <Typography variant="h5" fontWeight={"bold"}>{wallet.balance}₹</Typography>
              <Button size="sm" variant="dark" onClick={handleShow}>Add ₹</Button>
            </Stack>
          </div>
        </Grid2>
        {/* <Grid2 xs={6} sm={6} md={6} lg={3} xl={3}>
          <div className={`p-4 ${styles.atm}`} style={{ backgroundColor: "#ffc107", borderRadius: "10px" }}>
            <Typography>Bonus</Typography>
            <Typography variant="h5" fontWeight={"bold"}>{wallet.bonusAmount}₹</Typography>
          </div>

        </Grid2> */}
        <Grid2 xs={12} sm={12} md={612} lg={4} xl={4}>
          <div className={`p-4 ${styles.atm}`} style={{ backgroundColor: "#ffc107", borderRadius: "10px" }}>
            <Typography>Transfer</Typography>
            <Typography variant="h5"><SendIcon style={{ cursor: "pointer" }} onClick={() => setShowTransfer(true)} /></Typography>
          </div>

        </Grid2>
        <Grid2 xs={12} sm={12} md={12} lg={4} xl={4}>
          <div className={`p-4 ${styles.atm}`} style={{ backgroundColor: "#ffc107", borderRadius: "10px" }}>
            <Typography>Withdraw</Typography>
            <Stack direction="horizontal" style={{ justifyContent: "space-between" }}>
              <Typography variant="h5" fontWeight={"bold"}>{wallet.withdrawable}₹</Typography>
              <Button size="sm" variant="dark" onClick={() => setShowWithdraw(true)}>Withdraw ₹</Button>
            </Stack>
            {/* <Typography variant="h5"><AccountBalanceIcon style={{ cursor: "pointer" }} onClick={() => setShowWithdraw(true)} /></Typography> */}
          </div>

        </Grid2>
      </Grid2>
      <Stack className="p-2" gap={2}>
        <Typography variant="h5" className="text-white" fontWeight={"bolder"}>History</Typography>
        <Stack gap={2}>
          {history.length == 0 &&
            <Alert variant="filled" severity="warning" style={{ width: "fit-content", margin: "auto" }}>No transactions to show</Alert>
          }
          {history.length > 0 && history.map((item, index) => {
            // 0 - related to ride, requestId, poolId, from-userId, to-otherUser, type=payment, subtype=card or wallet
            // 1 - related to transfer, amount, type=transfer, from-userId, to-otherUser, subtype=sent or received
            // 2 - related to topup/withdrawal, amount, type=wallet, subtype=topup or withdraw
            var properties;
            if (item.event == 0) {
              if (uid == item.otherUser._id) {
                properties = setProperties(
                  "#c1faaf",
                  "For ride",
                  "#3dff00",
                  <CallReceivedIcon style={{ color: "#3dff00" }} />,
                  "Received for ride",
                  item.amount,
                  item.paymentStatus
                );
              }
              // I am paying
              else if (uid == item.userId._id) {
                properties = setProperties(
                  "#ff8787",
                  "For ride",
                  "#ff0000",
                  <CallMadeIcon style={{ color: "#ff0000" }} />,
                  "Paid for a ride",
                  item.requestId && item.requestId.isCouponApplied
                    ? item.requestId.fareAfterCommisionAndDiscount
                    : item.isBonusApplied == true
                      ? item.finalAmount
                      : item.amount + Number(item.commission),
                  item.paymentStatus
                );
              }
            } else if (item.event == 1) {
              // I am the sender
              if (uid == item.userId._id) {
                properties = setProperties(
                  "#fcb579",
                  "Transfer",
                  "#ff7300",
                  <CallMadeIcon style={{ color: "#ff7300" }} />,
                  "Sent via transfer",
                  item.amount,
                  item.paymentStatus
                );
              }
              // I am the receiver
              else if (uid == item.otherUser._id) {
                properties = setProperties(
                  "#c1faaf",
                  "Transfered to you",
                  "#3dff00",
                  <CallReceivedIcon style={{ color: "#3dff00" }} />,
                  "Received via transfer",
                  item.amount,
                  item.paymentStatus
                );
              }
            } else if (item.event == 2) {
              if (item.subType == "topup") {
                properties = setProperties(
                  "#ffde7a",
                  "Added to wallet",
                  "#ffc107",
                  <AddIcon style={{ color: "#ffc107" }} />,
                  "Topup",
                  item.paymentDetails.amount / 100,
                  item.paymentStatus
                );
              } else if (item.subType == "withdraw") {
                properties = setProperties(
                  "#ff8787",
                  "Withdrawn money",
                  "#ff0000",
                  <CallMadeIcon style={{ color: "#ff0000" }} />,
                  "Withdrawal",
                  item.amount,
                  item.paymentStatus
                );
              } else if (item.subType == "bonus") {
                console.log(item)
                properties = setProperties(
                  "#ffc107",
                  "Added to you bonus",
                  "#ffc107",
                  <AddIcon style={{ color: "#ffc107" }} />,
                  "Signup Bonus",
                  item.bonusAmount,
                  item.paymentStatus
                );
              }
            }
            return <Accordion sx={{ backgroundColor: "#1a1e21", color: "white" }} key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >{properties.title} {properties.icon}</AccordionSummary>
              <AccordionDetails className={`${styles.formfieldinverse}`}>
                <Table>
                  <tbody>
                    <tr>
                      <td style={{ color: "gray", fontWeight: "bold", border: "none" }}>Status</td>
                      <td className="text-white" style={{ border: "none" }}>
                        <Chip variant="filled" color={
                          properties.status == "failed" ? "error" : properties.status == "pending" ? "warning" : properties.status == "created" ? "warning" : "success"}
                          label={properties.status} /></td>
                    </tr>
                    <tr>
                      <td style={{ color: "gray", fontWeight: "bold", border: "none" }}>Amount</td>
                      <td className="text-white" style={{ border: "none" }}>{properties.amount}₹</td>
                    </tr>
                    <tr>
                      <td style={{ color: "gray", fontWeight: "bold", border: "none" }}>Note</td>
                      <td className="text-white" style={{ border: "none" }}>{properties.description}</td>
                    </tr>
                  </tbody>
                </Table>
              </AccordionDetails>
            </Accordion>
          })
          }
        </Stack>
      </Stack>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Balance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label htmlFor="inputPassword5">Please enter the amount</Form.Label>
          <Form.Control
            type="number"
            id="inputPassword5"
            aria-describedby="passwordHelpBlock"
            onChange={(e) => setAmountEntered(e.target.value)}
          />
          <Form.Text id="passwordHelpBlock" muted>
            Amount entered should be greater than 50₹.
          </Form.Text></Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={handleClose}>
            Close
          </Button>
          <Button variant="warning" onClick={handleAddBalance}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showTransfer} onHide={() => { setShowTransfer(false) }} centered>
        <Modal.Header closeButton>
          <Modal.Title>Transfer Balance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label htmlFor="inputPassword5">Phone Number</Form.Label>
          <InputGroup>
            <InputGroup.Text id="basic-addon3">
              +91
            </InputGroup.Text>
            <Form.Control
              type="number"
              id="inputPassword5"
              aria-describedby="passwordHelpBlock"
              onChange={(e) => setTransferTo(e.target.value)}
            />
          </InputGroup>
          <Form.Label htmlFor="inputPassword5">Please enter the amount</Form.Label>
          <Form.Control
            type="number"
            id="inputPassword5"
            aria-describedby="passwordHelpBlock"
            onChange={(e) => setTransferAmount(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={() => { setShowTransfer(false) }}>
            Close
          </Button>
          <Button variant="warning" onClick={transferHandler}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showWithdraw} onHide={() => { setShowWithdraw(false) }} centered>
        <Modal.Header closeButton>
          <Modal.Title>Withdraw Balance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label htmlFor="inputPassword5">Please enter the amount</Form.Label>
          <Form.Control
            type="number"
            id="inputPassword5"
            aria-describedby="passwordHelpBlock"
            onChange={(e) => setAmountEntered(e.target.value)}
          />
          <Form.Text id="passwordHelpBlock" muted>
            Amount entered should be greater than 50₹.
          </Form.Text></Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={() => { setShowWithdraw(false) }}>
            Close
          </Button>
          <Button variant="warning" onClick={handleAddBalance}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>)
  }
  else {
    return <Loader />
  }
}

export default Wallet;
