import axios from "axios";
import { useEffect, useMemo, memo, useState } from "react";
import styles from "../../styles/OverideStyles.module.css";
import { Navigate, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { configuration } from "../../apis/config";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import { Button, Form, InputGroup, Spinner, Stack } from "react-bootstrap";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CardLayout from "../../components/CardLayout";
import request from "../../utils/request"
import CheckIcon from '@mui/icons-material/Check';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "react-bootstrap";
import DeleteIcon from '@mui/icons-material/Delete';
import Loader from "../../components/Loader";
const CryptoJS = require("crypto-js");
const config = require("../../config");

const Account = () => {
  const nav = useNavigate();
  const [name, setName] = useState("");
  const [data, setData] = useState(null);
  const [foundPaymentMethods, setFoundPaymentMethods] = useState([]);
  const [select, setSelect] = useState(null);

  const [show, setShow] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  async function passwordHandler(event) {
    event.stopPropagation();
    event.preventDefault();
    if (newPassword == repeatPassword) {
      const op = String(CryptoJS.AES.encrypt(oldPassword, config.encKey));
      const np = String(CryptoJS.AES.encrypt(newPassword, config.encKey));
      request("POST", configuration.urls.changePassword, {
        old: op,
        new: np,
      })
        .then((resp) => {
          if (resp.data.status == true) {
            toast.success(resp.data.message);
          }
        })
        .catch((err) => {
          if (err.response.status == 400) {
            toast.error(err.response.data.message);
          }
        });
    } else {
      console.log(newPassword, repeatPassword)
      toast.error("Passwords didn't match!");
    }
  }

  useEffect(() => {
    request("POST", configuration.urls.account)
      .then((resp) => {
        setData(resp.data);
        setFoundPaymentMethods(resp.paymentMethods);
        setName(resp.data.name);
        setIsReady(true);
      })
      .catch((err) => {
        console.log(err.response.data.code);
        if (
          err.response.data.code == "JsonWebTokenError" ||
          err.response.data.code == "TokenExpiredError"
        ) {
          nav("/login");
        }
        setIsReady(true);
      });
  }, []);

  function updateName(event) {
    axios({
      method: configuration.urls.changeNameMethod,
      url: configuration.urls.changeName,
      headers: {
        Authorization: `Bearer ${getToken}`,
      },
      data: { name: name },
    })
      .then((resp) => {
        toast.success(resp.data.message);
      })
      .catch((err) => {
        if (err.response.data.code == "JsonWebTokenError") {
          nav("/login");
        }
        toast.error(err.response.data.message);
      });
  }

  function handleNewPaymentMethod(e) {
    var newPaymentDetails;
    e.preventDefault();
    if (select == "upi") {
      newPaymentDetails = { upiId: e.target.upiId.value, type: "upi" };
    }
    else if (select == "phone") {
      newPaymentDetails = { phone: e.target.phone.value, type: "phone" };
    }
    else if (select == "account") {
      newPaymentDetails = {
        type: "account",
        accountNumber: e.target.accountNumber.value,
        ifscCode: e.target.ifscCode.value,
        bankName: e.target.bankName.value
      };
    }

    request("POST", configuration.urls.addPaymentMethod, newPaymentDetails)
      .then((resp) => {
        setFoundPaymentMethods(pv => [...pv, resp.data]);
        toast.success("Added successfully!");
        handleClose();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
  }

  function removePaymentMethod(id) {
    request("POST", configuration.urls.removePaymentMethod, { id: id })
      .then((resp) => {
        setFoundPaymentMethods(pv => pv.filter((i, ind) => i._id !== id))
      })
      .catch((err) => {
        toast.error(err.response.data.message)
      });
  }

  if (isReady) {
    return (
      <>
        <div style={{ height: "100%", width: "100%", alignContent: "center" }}>
          <CardLayout title={"Account"}
            form={<>
              <InputGroup className="mb-3">
                <Form.Control
                  className={`${styles.formfield}`}
                  name="name"
                  id="name"
                  size="sm"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  defaultValue={name}
                // value={name}
                />
                <Button variant="outline-secondary" id="button-addon2" style={{ backgroundColor: "#ffc107", color: "black", borderColor: "#ffc107" }} onClick={updateName}>
                  Update
                </Button>
              </InputGroup>

              <InputGroup className="mb-3">
                <Form.Control
                  size="sm"
                  className={`${styles.formfield}`}
                  defaultValue={data.phone ? data.phone : ""}
                  contentEditable={false} />
                <Button variant="outline-secondary" id="button-addon2" style={{ backgroundColor: "#ffc107", color: "black", borderColor: "#ffc107" }}>
                  <CheckIcon color="black" />
                </Button>
              </InputGroup>

              <Accordion className="mb-3" sx={{ backgroundColor: "#1a1e21", color: "white" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >Payment Methods</AccordionSummary>
                <AccordionDetails style={{ backgroundColor: "#1a1e21" }}>
                  <Stack gap={2}>
                    {foundPaymentMethods.map((item, index) => {
                      return <div className="rounded p-2" style={{ backgroundColor: "#111315" }} key={index}>
                        <Stack direction="horizontal">
                          <Stack>
                            <Typography variant="caption" fontWeight={"bold"} color={"gray"}>Method: {item.type}</Typography>
                            {item.type == "upi" &&
                              <Typography variant="caption" color={"gray"}>UPI ID: {item.details.upiId}</Typography>
                            }
                            {item.type == "phone" &&
                              <Typography variant="caption" color={"gray"}>Phone: {item.details.phone}</Typography>
                            }
                            {item.type == "account" &&
                              <>
                                <Typography variant="caption" color={"gray"}>Account No.: {item.details.accountNumber}</Typography>
                                <Typography variant="caption" color={"gray"}>IFSC Code: {item.details.ifscCode}</Typography>
                                <Typography variant="caption" color={"gray"}>Bank: {item.details.bankName}</Typography>
                              </>
                            }
                          </Stack>
                          <div>
                            <DeleteIcon color="error" onClick={() => { removePaymentMethod(item._id) }} style={{ cursor: "pointer" }} />
                          </div>
                        </Stack>
                      </div>
                    })}
                    <div className="text-center">
                      <Button onClick={() => { setShow(true) }} size="sm" style={{ backgroundColor: "#ffc107", color: "black", borderColor: "#ffc107" }}>Add New</Button>
                    </div>
                  </Stack>
                </AccordionDetails>
              </Accordion>

              <Accordion sx={{ backgroundColor: "#1a1e21", color: "white" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >Change Password</AccordionSummary>
                <AccordionDetails className={`${styles.formfield}`}>
                  <Form.Control
                    className={`${styles.formfieldinverse} mb-3`}
                    size="sm"
                    type="password"
                    placeholder="Old Password"
                    id="pOne"
                    onChange={(e) => {
                      // e.stopPropagation();
                      setOldPassword(e.target.value);
                      e.stopPropagation();
                    }}
                    value={oldPassword}
                  />
                  <Form.Control
                    className={`${styles.formfieldinverse} mb-3`}
                    size="sm"
                    id="pTwo"
                    type="password"
                    placeholder="New Password"
                    onChange={(e) => {
                      e.stopPropagation();
                      setNewPassword(e.target.value);
                    }}
                    value={newPassword}
                  />
                  <Form.Control
                    className={`${styles.formfieldinverse} mb-3`}
                    size="sm"
                    id="pThree"
                    type="password"
                    placeholder="Repeat Password"
                    onChange={(e) => {
                      e.stopPropagation();
                      setRepeatPassword(e.target.value);
                    }}
                    value={repeatPassword}
                  />
                  <div className="text-center">
                    <Button onClick={passwordHandler} size="sm" style={{ backgroundColor: "#ffc107", color: "black", borderColor: "#ffc107" }}>Change</Button>
                  </div>
                </AccordionDetails>
              </Accordion>
            </>}
            submitHandler={updateName} />

          <Modal show={show} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>Add Payment Method</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleNewPaymentMethod}>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Select required size="sm" onChange={(e) => { setSelect(e.target.value) }}>
                    <option>Select</option>
                    <option value="upi">UPI</option>
                    <option value="phone">Phone Number (wallet)</option>
                    <option value="account">Account</option>
                  </Form.Select>
                </Form.Group>
                {select == "upi" &&
                  <Form.Group className="mb-3">
                    <Form.Control
                      name="upiId"
                      id="upiId"
                      size="sm"
                      placeholder="Enter your UPI id"
                    />
                  </Form.Group>
                }
                {select == "phone" &&
                  <Form.Group className="mb-3">
                    <Form.Control
                      name="phone"
                      id="phone"
                      size="sm"
                      placeholder="Enter your wallet phone number"
                    />
                  </Form.Group>
                }
                {select == "account" &&
                  <>
                    <Form.Group className="mb-3">
                      <Form.Control
                        name="accountNumber"
                        id="accountNumber"
                        size="sm"
                        placeholder="Enter your account number"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control
                        name="ifscCode"
                        id="ifscCode"
                        size="sm"
                        placeholder="Enter your IFSC code"
                      />
                    </Form.Group>
                    <Form.Group className="mb-1">
                      <Form.Control
                        name="bankName"
                        id="bankName"
                        size="sm"
                        placeholder="Enter your bank name"
                      />
                      <Form.Text className="text-muted">
                        NOTE: Please enter only savings account details
                      </Form.Text>
                    </Form.Group>
                  </>
                }
              </Modal.Body>
              <Modal.Footer>
                <Button variant="dark" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="warning" type="submit">
                  Add
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </div >
      </>
    );
  }
  else {
    return <Loader />
  }
};

export default memo(Account);
