import React from 'react'
import { Spinner } from 'react-bootstrap'
import { Typography } from '@mui/material'

export default function Loader() {
  return (
      <div style={{ height: "100%", width: "100%", alignContent: "center" }}>
            <div className="text-center">
                <Spinner animation="grow" variant="warning" style={{}} />
                <Typography className="text-white" variant="body2">Loading</Typography>
            </div>
        </div>
  )
}
