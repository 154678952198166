import Logo from "../../components/logo";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { toast, Toaster } from "react-hot-toast";
import styles from "../../styles/OverideStyles.module.css";
import axios from "axios";
import { Typography } from "@mui/material";
import { configuration } from "../../apis/config";
import FormLayout from "../../components/FormLayout";
import { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";

function ResendVfyPhone() {
  const nav = useNavigate();
  const [show, setShow] = useState(false);
  const [otp, setOtp] = useState("");
  const [hash, setHash] = useState("");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(5);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  function submitHandler(event) {
    event.preventDefault();
    const phone = event.target.phone.value;
    axios({
      method: configuration.urls.resendVerificationMethod,
      url: configuration.urls.resendVerification,
      data: { phone: phone },
    })
      .then((resp) => {
        if (resp.status == 200) {
          toast.success(resp.data.message);
          setHash(resp.data.data);
          setTimeout(() => {
            handleShow();
          }, 2000);
        } else {
          toast.error("Something went wrong!");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }
  function resendOTP() {
    setMinutes(0);
    setSeconds(5);
    axios({
      method: configuration.urls.resendOtpMethod,
      url: configuration.urls.resendOtp,
      data: {
        hash: hash,
      },
    })
      .then((resp) => {
        toast.success(resp.data.message);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  }
  function verifyHandler() {
    axios({
      method: configuration.urls.verifyOtpMethod,
      url: configuration.urls.verifyOtp,
      data: {
        hash: hash,
        otp: otp,
        unset: true,
      },
    })
      .then((resp) => {
        toast.success(resp.data.message);
        setTimeout(() => {
          handleClose();
          nav(`/login`);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  }
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        alignContent: "center",
      }}
      className={`${styles.loginBg}`}
    >
      <div
        style={{
          backgroundColor: "#171a1d",
          borderRadius: "10px",
          width: "auto",
          maxWidth: "350px",
          minWidth: "250px",
          margin: "auto",
        }}
        className="shadow"
      ><Toaster/>
        <Form className={"p-4"} onSubmit={submitHandler}>
          <Toaster />
          <div className="mb-3">
            <Typography className="text-white" variant="h4" fontWeight={"bolder"}>Resend OTP</Typography>
          </div>
          <Form.Group controlId="phone" className="mb-3">
            <Form.Label className="col-form-label text-white">Phone</Form.Label>
            <Form.Control
              className={styles.formfield}
              size="sm"
              name="phone"
              id="phone"
              type="phone"
              placeholder="Enter your phone number"
            />
          </Form.Group>
          <Button
            size="sm"
            type="submit"
            className={`btn btn-warning ${styles.loginbtn}`}
          >
            Submit
          </Button>
        </Form>
        <Modal show={show} onHide={handleClose} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Verify Phone</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container gx-5">
              <div className="mb-3">
                <Toaster />
                <label htmlFor="otp" className="form-label">
                  Enter OTP
                </label>
                <input
                  onChange={(e) => {
                    setOtp(e.target.value);
                  }}
                  name="otp"
                  type="number"
                  className="form-control"
                  id="otp"
                  aria-describedby="otpHelp"
                />
              </div>
              <div className="text-center">
                {seconds > 0 || minutes > 0 ? (
                  <p>
                    Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </p>
                ) : (
                  <p>Didn't recieve code?</p>
                )}
                <Button
                  disabled={seconds > 0 || minutes > 0}
                  style={{
                    color: seconds > 0 || minutes > 0 ? "#a8abad" : "#ffc107",
                  }}
                  onClick={resendOTP}
                  variant="link"
                >
                  Resend OTP
                </Button>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="dark" onClick={handleClose}>
              Close
            </Button>
            <Button variant="warning" onClick={verifyHandler}>
              Verify
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default ResendVfyPhone;
