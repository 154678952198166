import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getUserDetails } from "../../store/features/userDetails.store";
import FormLayout from "../../components/FormLayout";
import { Alert, Button, Tab, Tabs, Row, Col } from "react-bootstrap";
import { Form } from "react-bootstrap";
import styles from "../../styles/OverideStyles.module.css";
import { toast, Toaster } from "react-hot-toast";
import Dropzone from "react-dropzone";
import { configuration } from "../../apis/config";
import axios from "axios";
import { useMemo } from "react";
import { checkUser } from "../../store/features/authUser.store";
import { Link, Navigate } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import CardLayoutCommander from "../../components/CardLayoutCommander";
import PoolCard from "../../components/PoolCard";

// Firebase
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import request from "../../utils/request";
import Loader from "../../components/Loader";

function Commander() {
  const [carFiles, setCarFiles] = useState([]);
  const [pools, setPools] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [positiveResp, setPositiveResp] = useState();
  const dispatch = useDispatch();

  // Firebase
  const firebaseConfig = {
    storageBucket: "gs://commute-92221.appspot.com",
  };
  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);

  function uploadImage(carId, img) {
    const storageRef = ref(storage, `${carId}.jpg`);
    uploadBytes(storageRef, img)
      .then((r) => {
        return r;
      })
      .catch((er) => {
        return er;
      });
  }

  const style = useMemo(() => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 10,
    borderColor: 'gray',
    borderStyle: 'dashed',
    backgroundColor: '#212529',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  }), []);


  useEffect(() => {
    request("POST", configuration.urls.getPools)
      .then((resp) => {
        setPools(resp.data);
        setIsReady(true);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
        setIsReady(true);
      });
  }, [positiveResp]);

  const user = useSelector((state) => state.authUser);
  console.log(user)

  const removeCarFile = (file) => () => {
    const newFiles = [...carFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setCarFiles(newFiles);
  };

  async function submitHandler(event) {
    event.preventDefault();
    const vName = String(event.target.vehicleName.value);
    const vNumber = String(event.target.vehicleNumber.value);
    const vColor = String(event.target.vehicleColor.value);
    const vType = String(event.target.vehicleType.value);
    const data = {
      name: vName,
      color: vColor,
      type: vType,
      number: vNumber,
    };

    try {
      const register = await axios({
        method: configuration.urls.vehiclesRegisterMethod,
        url: configuration.urls.vehiclesRegister,
        headers: {
          Authorization: `Bearer ${check.token}`,
        },
        data: data,
      });
      if (register.status == 200) {
        toast.success(register.data.message);
        uploadImage(register.data.data._id, carFiles[0]);
        setTimeout(() => {
          setPositiveResp(true);
        }, 2000);
      }
    } catch (err) {
      if (err) {
        toast.error(err.response.data.message);
      }
    }
  }

  if (isReady) {
    if (!user.userDetails.phone_no_verified) {
      return (
        <>
          <FormLayout light={true}>
            {/* <h1>Please verify your email/phone from settings first</h1>; */}
            <Form className={styles.loginform}>
              <Alert key={"warning"} variant={"warning"}>
                Please verify your email/phone to continue.
              </Alert>
            </Form>
          </FormLayout>
        </>
      );
    } else if (user.userDetails.is_commander == true) {
      return (
        <>
          <div className={`${styles.commanderBg}`} style={{ height: "100%", width: "100%", alignContent: "center", overflowY: "auto" }}>
            <div
              className="p-3 shadow-lg m-auto"
              style={{
                width: "auto",
                minWidth: "250px",
                maxWidth: "550px",
                backgroundColor: "#111315",
                borderRadius: "10px",
              }}
            >
              <Toaster />
              <Stack className="mb-3" direction={"row"} justifyContent={"space-between"}>
                <Typography className="mb-3" variant="h5" color={"white"} fontWeight={"bolder"}>
                  Your Pool History
                </Typography>
                {pools.length != 0 &&
                  <div>
                    <Link to={"/app/pools/create"} className="btn-disabled">
                      <Button size="sm" style={{ backgroundColor: "#ffc107", borderColor: "#ffc107", color: "black" }}>Create</Button>
                    </Link>
                  </div>
                }
              </Stack>
              <Stack gap={2}>
                {pools.length == 0 &&
                  <>
                    <Typography className="text-center" color={"gray"}>Create your first pool to get started!</Typography>
                    <Link to={"/app/pools/create"} className="text-center">
                      <Button size="sm" style={{ backgroundColor: "#ffc107", borderColor: "#ffc107", color: "black" }}>Let's Go</Button>
                    </Link>
                  </>
                }
                {pools.map((pool, index) => {
                  return <PoolCard data={pool} />;
                })}
              </Stack>

            </div>
          </div>
        </>
      );
    }
    // else if (user.userDetails.kycStatus == "pending") {
    //   return (
    //     <>
    //       <FormLayout light={true}>
    //         <Form className={styles.loginform}>
    //           <Alert key={"warning"} variant={"warning"}>
    //             Your application is under review. Please check after a while.
    //           </Alert>
    //         </Form>
    //       </FormLayout>
    //     </>
    //   );
    // } 
    else {
      return (
        <div className={`${styles.commanderBg}`} style={{ height: "100%", width: "100%", alignContent: "center" }}>
          <div
            className="p-3 shadow-lg m-auto"
            style={{
              width: "auto",
              minWidth: "250px",
              maxWidth: "400px",
              backgroundColor: "#111315",
              borderRadius: "10px",
            }}
          >
            <Form
              className="mb-3"
              onSubmit={submitHandler}
              encType="multipart/form-data"
            >
              <Toaster />
              <Typography className="mb-3" variant="h5" color={"white"} fontWeight={"bolder"}>
                Become a command now
              </Typography>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="vehicleName">
                  <Form.Label className="col-form-label text-white">Vehicle Name</Form.Label>
                  <Form.Control
                    className={styles.formfield}
                    size="sm"
                    name="vehicleName"
                    type="text"
                    placeholder="Enter your vehicle name"
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="vehicleType">
                  <Form.Label className="col-form-label text-white">Type</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    size="sm"
                    name="vehicleType"
                    className={styles.formfield}
                    required
                  >
                    {/* <option>Vehicle Type</option> */}
                    <option value="Car">Car</option>
                    <option value="Bike">Bike</option>
                    <option value="Other">Other</option>
                  </Form.Select>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="vehicleColor">
                  <Form.Label className="col-form-label text-white">Color</Form.Label>
                  <Form.Control
                    className={styles.formfield}
                    size="sm"
                    name="vehicleColor"
                    type="text"
                    placeholder="Enter your vehicle color"
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="vehicleNumber">
                  <Form.Label className="col-form-label text-white">Number</Form.Label>
                  <Form.Control
                    size="sm"
                    name="vehicleNumber"
                    className={styles.formfield}
                    type="text"
                    placeholder="Enter your vehicle number"
                  />
                </Form.Group>
              </Row>
              {/* Below is for vehicles */}
              <Form.Group>
                <Form.Label className="col-form-label text-white">
                  Upload Car Images
                </Form.Label>
                {carFiles.length == 0 && (
                  <Dropzone
                    multiple={false}
                    onDrop={(acceptedFiles) => {
                      setCarFiles(acceptedFiles);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section
                        className="container"
                        style={{ paddingBottom: "10px" }}
                      >
                        <div
                          {...getRootProps({ style })}
                        >
                          <input {...getInputProps()} />
                          <p style={{ marginBottom: "0px" }}>
                            Drag 'n' drop some files here, or click to select
                            files
                          </p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                )}
              </Form.Group>
              {carFiles.map((file) => (
                <li style={{ padding: "5px" }} key={file.path}>
                  {file.name}{" "}
                  <button
                    className="btn btn-warning"
                    onClick={removeCarFile(file)}
                  >
                    X
                  </button>
                </li>
              ))}
              <div className="d-grid gap-2">
                <Button
                  type="submit"
                  size="sm"
                  className="btn btn-warning"
                  style={{ fontWeight: "bold" }}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      );
    }
  }
  else {
    return <Loader />
  }
}

export default Commander;
