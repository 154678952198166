import { Button, Card } from "react-bootstrap";
import { Stack } from "@mui/system";
import { Table } from "react-bootstrap";
import { Alert } from "react-bootstrap";
import style from "../styles/OverideStyles.module.css";
import { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { useEffect, useRef } from "react";
import { TextField, Typography } from "@mui/material";
import { ButtonGroup, Button as bttn, Accordion, AccordionActions, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from "axios";
import { configuration } from "../apis/config";
import config from "../config";
import { Toaster, toast } from "react-hot-toast";
// import socketIOClient from "socket.io-client";
import Carousel from 'react-material-ui-carousel'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Paper } from '@mui/material'
// var socket = socketIOClient(config.socketUrl);
import { useNavigate } from "react-router-dom";
import { socket } from '../socket';
import request from "../utils/request";

function PoolDetailsContent(props) {
  const nav = useNavigate();
  const [show, setShow] = useState(false);
  const [disableTooltip, setDisableTooltip] = useState(false);
  const [disableJoinBtn, setDisableJoinBtn] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("online");
  const [note, setNote] = useState(null);
  const [num, setNum] = useState(0);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [dontAllowMessage, setAllowMessage] = useState(true);
  const [isCustom, setIsCustom] = useState(false);
  const inputFromReff = useRef();
  const inputToReff = useRef();
  useEffect(() => {
    setDisableJoinBtn(props.isUserActive);
    props.data.requests.forEach((ride) => {
      if (ride.status == "accepted" || ride.status == "pending") {
        setDisableJoinBtn(true);
        setAllowMessage(false);
        setDisableTooltip(false);
      }
      else {
        setDisableJoinBtn(false);
        setAllowMessage(true);
        setDisableTooltip(true);
      }
    })
    socket.on("connection", () => {
      console.log("Connected");
    });
    socket.on("join", () => {
      console.log("Join clicked");
    });

    socket.on("disconnect", () => {
      console.log("disconnected");
    });
  }, [props]);
  var seatsArray = [];
  for (let i = 0; i < props.data.seats - props.data.seatsAvailable; i++) {
    seatsArray.push(i);
  }
  var seatsAvailableArray = [];
  for (let i = 0; i < props.data.seatsAvailable; i++) {
    seatsAvailableArray.push(i);
  }
  const handlePlaceChangedFrom = async () => {
    const [place] = await inputFromReff.current.getPlaces();
    if (place) {
      setFrom(place);
      setIsCustom(true);
    }
  };

  const handlePlaceChangedTo = () => {
    const [place] = inputToReff.current.getPlaces();
    if (place) {
      setTo(place);
      setIsCustom(true);
    }
  };
  const getToken = localStorage.getItem("token");

  function handleJoin() {
    request("POST", configuration.urls.joinPool, {
      poolId: props.poolid,
      seats: num,
      from: from ? from.name : props.data.from,
      to: to ? to.name : props.data.to,
      fromWholeAddress: from
        ? from.formatted_address
        : props.data.fromWholeAddress,
      toWholeAddress: to ? to.formatted_address : props.data.toWholeAddress,
      isCustom: isCustom,
      note: note,
      paymentMode: paymentMethod
    }
    )
      .then((resp) => {
        setDisableJoinBtn(true);
        handleClose();
        toast.success(resp.data.message);
        setAllowMessage(false);
        setDisableTooltip(false);
        props.joinFeedback(true);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }

  const ic = (
    <svg
      fill="red"
      width="32px"
      height="35px"
      version="1.1"
      viewBox="0 0 700 550"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <path d="m457.8 481.25c-3.2266-0.007812-6.1836-1.7891-7.6992-4.6367l-45.852-86.363c-0.47656-0.90234-0.80078-1.8789-0.96094-2.8867-17.938-131.25 0-267.84 0-269.15v-0.003906c0.625-4.832 5.0547-8.2383 9.8867-7.6094 4.832 0.625 8.2383 5.0547 7.6133 9.8867-10.117 87.352-10.117 175.58 0 262.94l45.062 84.961c1.1133 2.0508 1.3594 4.4648 0.68359 6.6992-0.67188 2.2344-2.2109 4.1055-4.2734 5.2031-1.3906 0.66797-2.918 0.99609-4.4609 0.96094z" />
        <path d="m244.04 481.25c-1.4258-0.019531-2.8281-0.34766-4.1133-0.96094-2.0781-1.082-3.6367-2.9492-4.3242-5.1836-0.69141-2.2344-0.45703-4.6562 0.64844-6.7188l45.062-84.961c10.086-87.211 10.086-175.29 0-262.5-0.62891-4.832 2.7812-9.2617 7.6133-9.8867 4.832-0.62891 9.2578 2.7773 9.8867 7.6094 0 1.3125 17.938 137.9 0 269.15v0.003906c-0.16016 1.0078-0.48438 1.9844-0.96094 2.8867l-45.852 86.363c-1.6758 2.7617-4.7383 4.375-7.9609 4.1992z" />
        <path d="m405.56 178.85h-109.55c-4.832 0-8.75-3.918-8.75-8.75 0-4.8359 3.918-8.75 8.75-8.75h109.55c4.832 0 8.75 3.9141 8.75 8.75 0 4.832-3.918 8.75-8.75 8.75z" />
        <path d="m404.51 230.56h-107.01c-4.832 0-8.75-3.918-8.75-8.75s3.918-8.75 8.75-8.75h107.01c4.832 0 8.75 3.918 8.75 8.75s-3.918 8.75-8.75 8.75z" />
        <path d="m403.02 282.36h-104.21c-4.832 0-8.75-3.918-8.75-8.75s3.918-8.75 8.75-8.75h104.21c4.8359 0 8.75 3.918 8.75 8.75s-3.9141 8.75-8.75 8.75z" />
        <path d="m405.12 334.07h-108.24c-4.832 0-8.75-3.918-8.75-8.75s3.918-8.75 8.75-8.75h108.24c4.832 0 8.75 3.918 8.75 8.75s-3.918 8.75-8.75 8.75z" />
        <path d="m440.39 448.44h-178.94c-4.832 0-8.75-3.918-8.75-8.75s3.918-8.75 8.75-8.75h178.94c4.832 0 8.75 3.918 8.75 8.75s-3.918 8.75-8.75 8.75z" />
        <path d="m249.46 551.25c-53.727 0-62.125-67.637-65.363-93.102-4.0234-32.375 4.7266-53.727 26.25-63.613l0.003906 0.003906c2.4766-1.25 5.3984-1.25 7.875 0 2.4258 1.3281 4.082 3.7305 4.4609 6.4727 6.9141 49.176 21.961 72.188 47.426 72.188h159.43c26.25 0.61328 41.211-22.574 48.125-72.188 0.37891-2.7422 2.0312-5.1445 4.4609-6.4727 2.4766-1.25 5.3984-1.25 7.875 0 21.176 9.8867 29.926 31.238 26.25 63.613-3.5859 25.461-11.988 93.098-65.801 93.098h-201.25zm-42.172-133c-4.9883 6.4766-8.3125 17.5-5.7734 37.711 6.7383 54.164 21.699 78.75 48.738 77.789h200.02c26.25 0 41.477-24.062 48.211-77.789 2.5391-20.039-0.78906-31.238-5.7734-37.711-5.1641 27.738-18.988 72.711-62.387 72.711h-160.74c-43.398 0-57.137-44.711-62.297-72.711z" />
        <path d="m441.52 394.89h-183.05c-0.66797 0.09375-1.3477 0.09375-2.0156 0-29.926-7.0859-48.387-49.875-45.062-104.12 2.4023-24.137 7.2383-47.973 14.438-71.137 8.75-30.801 21.438-71.75 23.449-77.176h0.003906c2.9531-10.266 9.3672-19.195 18.156-25.273 8.7852-6.0742 19.406-8.9258 30.055-8.0625h105c10.602-0.87109 21.184 1.9414 29.949 7.9688 8.7695 6.0273 15.188 14.895 18.176 25.105 2.0117 5.4258 14.613 46.375 23.449 77.176 7.2031 23.164 12.039 46.996 14.438 71.137 3.3242 54.25-15.137 97.039-45.062 104.12-0.625 0.17188-1.2734 0.25781-1.9258 0.26172zm-182-17.5h180.95c20.211-5.7734 33.336-42.352 30.711-85.836-2.3789-22.859-7.0117-45.426-13.824-67.375-9.2734-32.461-21.613-71.836-23.102-75.949-8.75-23.801-27.648-21.961-29.75-21.699l-109.11-0.003906c-2.0117 0-20.914-2.1016-29.664 21.699-1.4883 4.1133-13.824 43.75-23.102 75.949l0.003907 0.003906c-6.8125 21.949-11.445 44.516-13.824 67.375-2.625 43.488 10.5 80.062 30.711 85.836z" />
        <path d="m411.25 126.7c-3.5547 0.019531-6.7695-2.1094-8.1328-5.3945-1.3633-3.2812-0.60547-7.0625 1.9219-9.5664 7.2617-7.2617 2.9766-31.324-1.1367-54.602l-1.4023-5.1602c-4.6367-26.863-20.039-25.727-20.211-25.727h-63.703c-2.1875 0-16.625 0-21.176 25.637l-0.875 5.0742c-4.1992 23.273-8.75 47.426-1.2266 54.688h0.003906c3.3945 3.4141 3.3945 8.9258 0 12.34-1.6445 1.6562-3.8789 2.5859-6.2109 2.5859-2.3359 0-4.5703-0.92969-6.2148-2.5859-13.738-13.738-8.75-39.898-3.5859-70l0.875-4.9883c6.0352-34.125 28.348-41.211 39.285-40.25h61.949c10.062-1.0508 32.375 6.0391 38.414 40.074l0.96094 5.1641c5.3359 30.188 9.9766 56.262-3.6758 70h0.003906c-1.5391 1.6172-3.6328 2.5898-5.8633 2.7109z" />
        <path d="m486.32 411.25c-1.043-0.003906-2.0781-0.17969-3.0625-0.52344l-44.711-16.363c-4.543-1.6445-6.8906-6.6602-5.25-11.199 0.78906-2.2031 2.4258-3.9961 4.5469-4.9844 2.1211-0.98438 4.5469-1.082 6.7383-0.26563l44.711 16.363 0.003906-0.003906c3.9922 1.4492 6.3672 5.5547 5.6328 9.7383-0.73438 4.1797-4.3633 7.2344-8.6094 7.2383z" />
        <path d="m213.68 411.25c-4.2461-0.003906-7.875-3.0586-8.6094-7.2383-0.73438-4.1836 1.6406-8.2891 5.6328-9.7383l44.711-16.363 0.003906 0.003906c2.1914-0.81641 4.6172-0.71875 6.7383 0.26563 2.1211 0.98828 3.7578 2.7812 4.5469 4.9844 1.6406 4.5391-0.70703 9.5547-5.25 11.199l-44.711 16.363c-0.98438 0.34375-2.0195 0.51953-3.0625 0.52344z" />
      </g>
    </svg>
  );
  const icFilled = (
    <svg
      fill="grey"
      width="32px"
      height="35px"
      version="1.1"
      viewBox="0 0 700 550"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <path d="m457.8 481.25c-3.2266-0.007812-6.1836-1.7891-7.6992-4.6367l-45.852-86.363c-0.47656-0.90234-0.80078-1.8789-0.96094-2.8867-17.938-131.25 0-267.84 0-269.15v-0.003906c0.625-4.832 5.0547-8.2383 9.8867-7.6094 4.832 0.625 8.2383 5.0547 7.6133 9.8867-10.117 87.352-10.117 175.58 0 262.94l45.062 84.961c1.1133 2.0508 1.3594 4.4648 0.68359 6.6992-0.67188 2.2344-2.2109 4.1055-4.2734 5.2031-1.3906 0.66797-2.918 0.99609-4.4609 0.96094z" />
        <path d="m244.04 481.25c-1.4258-0.019531-2.8281-0.34766-4.1133-0.96094-2.0781-1.082-3.6367-2.9492-4.3242-5.1836-0.69141-2.2344-0.45703-4.6562 0.64844-6.7188l45.062-84.961c10.086-87.211 10.086-175.29 0-262.5-0.62891-4.832 2.7812-9.2617 7.6133-9.8867 4.832-0.62891 9.2578 2.7773 9.8867 7.6094 0 1.3125 17.938 137.9 0 269.15v0.003906c-0.16016 1.0078-0.48438 1.9844-0.96094 2.8867l-45.852 86.363c-1.6758 2.7617-4.7383 4.375-7.9609 4.1992z" />
        <path d="m405.56 178.85h-109.55c-4.832 0-8.75-3.918-8.75-8.75 0-4.8359 3.918-8.75 8.75-8.75h109.55c4.832 0 8.75 3.9141 8.75 8.75 0 4.832-3.918 8.75-8.75 8.75z" />
        <path d="m404.51 230.56h-107.01c-4.832 0-8.75-3.918-8.75-8.75s3.918-8.75 8.75-8.75h107.01c4.832 0 8.75 3.918 8.75 8.75s-3.918 8.75-8.75 8.75z" />
        <path d="m403.02 282.36h-104.21c-4.832 0-8.75-3.918-8.75-8.75s3.918-8.75 8.75-8.75h104.21c4.8359 0 8.75 3.918 8.75 8.75s-3.9141 8.75-8.75 8.75z" />
        <path d="m405.12 334.07h-108.24c-4.832 0-8.75-3.918-8.75-8.75s3.918-8.75 8.75-8.75h108.24c4.832 0 8.75 3.918 8.75 8.75s-3.918 8.75-8.75 8.75z" />
        <path d="m440.39 448.44h-178.94c-4.832 0-8.75-3.918-8.75-8.75s3.918-8.75 8.75-8.75h178.94c4.832 0 8.75 3.918 8.75 8.75s-3.918 8.75-8.75 8.75z" />
        <path d="m249.46 551.25c-53.727 0-62.125-67.637-65.363-93.102-4.0234-32.375 4.7266-53.727 26.25-63.613l0.003906 0.003906c2.4766-1.25 5.3984-1.25 7.875 0 2.4258 1.3281 4.082 3.7305 4.4609 6.4727 6.9141 49.176 21.961 72.188 47.426 72.188h159.43c26.25 0.61328 41.211-22.574 48.125-72.188 0.37891-2.7422 2.0312-5.1445 4.4609-6.4727 2.4766-1.25 5.3984-1.25 7.875 0 21.176 9.8867 29.926 31.238 26.25 63.613-3.5859 25.461-11.988 93.098-65.801 93.098h-201.25zm-42.172-133c-4.9883 6.4766-8.3125 17.5-5.7734 37.711 6.7383 54.164 21.699 78.75 48.738 77.789h200.02c26.25 0 41.477-24.062 48.211-77.789 2.5391-20.039-0.78906-31.238-5.7734-37.711-5.1641 27.738-18.988 72.711-62.387 72.711h-160.74c-43.398 0-57.137-44.711-62.297-72.711z" />
        <path d="m441.52 394.89h-183.05c-0.66797 0.09375-1.3477 0.09375-2.0156 0-29.926-7.0859-48.387-49.875-45.062-104.12 2.4023-24.137 7.2383-47.973 14.438-71.137 8.75-30.801 21.438-71.75 23.449-77.176h0.003906c2.9531-10.266 9.3672-19.195 18.156-25.273 8.7852-6.0742 19.406-8.9258 30.055-8.0625h105c10.602-0.87109 21.184 1.9414 29.949 7.9688 8.7695 6.0273 15.188 14.895 18.176 25.105 2.0117 5.4258 14.613 46.375 23.449 77.176 7.2031 23.164 12.039 46.996 14.438 71.137 3.3242 54.25-15.137 97.039-45.062 104.12-0.625 0.17188-1.2734 0.25781-1.9258 0.26172zm-182-17.5h180.95c20.211-5.7734 33.336-42.352 30.711-85.836-2.3789-22.859-7.0117-45.426-13.824-67.375-9.2734-32.461-21.613-71.836-23.102-75.949-8.75-23.801-27.648-21.961-29.75-21.699l-109.11-0.003906c-2.0117 0-20.914-2.1016-29.664 21.699-1.4883 4.1133-13.824 43.75-23.102 75.949l0.003907 0.003906c-6.8125 21.949-11.445 44.516-13.824 67.375-2.625 43.488 10.5 80.062 30.711 85.836z" />
        <path d="m411.25 126.7c-3.5547 0.019531-6.7695-2.1094-8.1328-5.3945-1.3633-3.2812-0.60547-7.0625 1.9219-9.5664 7.2617-7.2617 2.9766-31.324-1.1367-54.602l-1.4023-5.1602c-4.6367-26.863-20.039-25.727-20.211-25.727h-63.703c-2.1875 0-16.625 0-21.176 25.637l-0.875 5.0742c-4.1992 23.273-8.75 47.426-1.2266 54.688h0.003906c3.3945 3.4141 3.3945 8.9258 0 12.34-1.6445 1.6562-3.8789 2.5859-6.2109 2.5859-2.3359 0-4.5703-0.92969-6.2148-2.5859-13.738-13.738-8.75-39.898-3.5859-70l0.875-4.9883c6.0352-34.125 28.348-41.211 39.285-40.25h61.949c10.062-1.0508 32.375 6.0391 38.414 40.074l0.96094 5.1641c5.3359 30.188 9.9766 56.262-3.6758 70h0.003906c-1.5391 1.6172-3.6328 2.5898-5.8633 2.7109z" />
        <path d="m486.32 411.25c-1.043-0.003906-2.0781-0.17969-3.0625-0.52344l-44.711-16.363c-4.543-1.6445-6.8906-6.6602-5.25-11.199 0.78906-2.2031 2.4258-3.9961 4.5469-4.9844 2.1211-0.98438 4.5469-1.082 6.7383-0.26563l44.711 16.363 0.003906-0.003906c3.9922 1.4492 6.3672 5.5547 5.6328 9.7383-0.73438 4.1797-4.3633 7.2344-8.6094 7.2383z" />
        <path d="m213.68 411.25c-4.2461-0.003906-7.875-3.0586-8.6094-7.2383-0.73438-4.1836 1.6406-8.2891 5.6328-9.7383l44.711-16.363 0.003906 0.003906c2.1914-0.81641 4.6172-0.71875 6.7383 0.26563 2.1211 0.98828 3.7578 2.7812 4.5469 4.9844 1.6406 4.5391-0.70703 9.5547-5.25 11.199l-44.711 16.363c-0.98438 0.34375-2.0195 0.51953-3.0625 0.52344z" />
      </g>
    </svg>
  );
  var items = [
    {
      name: "Random Name #1",
      path: "https://cdni.autocarindia.com/utils/imageresizer.ashx?n=https://cms.haymarketindia.net/model/uploads/modelimages/Hyundai-Grand-i10-Nios-200120231541.jpg&w=872&h=578&q=75&c=1",
      description: "Probably the most random thing you have ever seen!"
    },
    {
      name: "Random Name #2",
      path: "https://cdni.autocarindia.com/utils/imageresizer.ashx?n=https://cms.haymarketindia.net/model/uploads/modelimages/Hyundai-Grand-i10-Nios-200120231541.jpg&w=872&h=578&q=75&c=1",
      description: "Hello World!"
    }
  ]
  function Item(props) {
    return (
      <img
        className="rounded shadow-sm"
        width="100%"
        height={"350px"}
        style={{ objectFit: "contain" }}
        src={props.item.path}
      ></img>
    )
  }

  function startConversation() {
    socket.emit("startConversation", { with: props.data.userId });
  }

  socket.on("startConversationHandshake", (data) => {
    if (data.handshake == true) {
      console.log(true)
      nav(`/app/message/${props.data.userId}`)
    }
  })

  return (
    <>
      <Toaster />
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack>
            <Table borderless style={{ margin: "auto" }}>
              <tbody>
                <tr>
                  <td >
                    <Typography fontWeight={"bold"}>From:</Typography>
                  </td>
                  <td>
                    <p>{from ? from.name : props.data.from}</p>
                  </td>
                </tr>
                <tr>
                  <td >
                    <Typography fontWeight={"bold"}>To:</Typography>
                  </td>
                  <td>
                    <p>{to ? to.name : props.data.to}</p>
                  </td>
                </tr>
                <tr>
                  <td >
                    <Typography fontWeight={"bold"}>Select Seats:</Typography>
                  </td>
                  <td>
                    <ButtonGroup
                      variant="outlined"
                      aria-label="outlined button group"
                    >
                      <Button
                        variant="warning"
                        size="sm"
                        onClick={() => {
                          if (num != 0) {
                            setNum(num - 1);
                          } else {
                            setNum(0);
                          }
                        }}
                      >
                        -
                      </Button>
                      <Typography style={{ margin: "0px", padding: "5px" }}>{num}</Typography>
                      <Button
                        variant="warning"
                        size="sm"
                        onClick={() => {
                          if (num > seatsAvailableArray.length) {
                            setNum(0);
                          } else {
                            setNum(num + 1);
                          }
                        }}
                      >
                        +
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
                <tr >
                  <td >
                    <Typography fontWeight={"bold"}>Fare:</Typography>
                  </td>
                  <td>
                    <p style={{ color: "green" }}>{props.data.fareAfterCommision * num}₹</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography fontWeight={"bold"}>
                      Payment Mode
                    </Typography>
                  </td>
                  <td>
                    <Stack>
                      <Form>
                        <Form.Check
                          label="Online (Card/Wallet/UPI)"
                          name="group1"
                          checked={paymentMethod == "online" ? true : false}
                          type={"radio"}
                          onChange={(e) => { setPaymentMethod("online") }}
                        />
                        <Form.Check
                          inline
                          name="group1"
                          label="Cash"
                          type={"radio"}
                          checked={paymentMethod == "cash" ? true : false}
                          onChange={(e) => { setPaymentMethod("cash") }}
                        />
                      </Form>
                    </Stack>
                  </td>
                </tr>
                <tr >
                  <td >
                    <Typography fontWeight={"bold"}>Note:</Typography>
                  </td>
                  <td>
                    <p style={{ color: "green" }}><TextField multiline size="small" onChange={(e) => { setNote(e.target.value) }} /></p>
                  </td>
                </tr>
              </tbody>
            </Table>
            <Alert variant="warning" style={{ display: from ? "" : "none" }}>
              Note: Commander will respond to your request soon!
            </Alert>
          </Stack>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={handleClose} size="sm">
            Close
          </Button>
          <Button variant="warning" onClick={handleJoin} size="sm">
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container" style={{ padding: "15px" }}>
        <div className="row">
          <div
            className="col-sm-12 col-md-12 col-lg-4 col-xl-4"
            style={{ padding: "15px" }}
          >
            <Carousel>
              {
                items.map((item, i) => <Item key={i} item={item} />)
              }
            </Carousel>
          </div>
          <div
            className="col-sm-12 col-md-12 col-lg-8 col-xl-8"
            style={{ padding: "15px" }}
          >
            <Card className="rounded shadow-sm" style={{ backgroundColor: "#1a1e21" }}>
              <Card.Body>
                <Stack>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <Table borderless style={{ margin: "auto" }}>
                        <tbody>
                          <tr>
                            <td className={`${style.limittd}`}>
                              <Typography fontWeight={"bold"}>From:</Typography>
                            </td>
                            <td className="text-white">
                              <p>{props.data.from}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className={`${style.limittd}`}>
                              <Typography fontWeight={"bold"}>To:</Typography>
                            </td>
                            <td className="text-white">
                              <p>{props.data.to}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className={`${style.limittd}`}>
                              <Typography fontWeight={"bold"}>Seats Available:</Typography>
                            </td>
                            <td >
                              {seatsArray.map(() => {
                                return <>{ic}</>;
                              })}
                              {seatsAvailableArray.map(() => {
                                return <>{icFilled}</>;
                              })}
                            </td>
                          </tr>
                          <tr>
                            <td className={`${style.limittd}`}>
                              <Typography fontWeight={"bold"}>Starts At:</Typography>
                            </td>
                            <td className="text-white">
                              <p>{props.data.starts}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className={`${style.limittd}`}>
                              <Typography fontWeight={"bold"}>Pickup At:</Typography>
                            </td>
                            <td className="text-white">
                              <p>{props.data.pickup}</p>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <Table borderless style={{ margin: "auto" }}>
                        <tbody>
                          <tr>
                            <td className={`${style.limittd}`}>
                              <Typography fontWeight={"bold"}>Fare:</Typography>
                            </td>
                            <td>
                              <p style={{ color: "green", fontWeight: "bolder" }}>
                                {props.data.fareAfterCommision}₹
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td className={`${style.limittd}`}>
                              <Typography fontWeight={"bold"}>Date:</Typography>
                            </td>
                            <td className="text-white">
                              <p>{props.data.date}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className={`${style.limittd}`}>
                              <Typography fontWeight={"bold"}>Name:</Typography>
                            </td>
                            <td className="text-white">
                              <p>{props.data.vehicle.name}</p>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="container" style={{ padding: "10px" }}>
                    <Accordion sx={{ backgroundColor: "#1a1e21", color: "#ffc107" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >Join on the way ?</AccordionSummary>
                      <AccordionDetails className={`text-center`} sx={{ backgroundColor: "#1a1e21" }}>
                        <Stack spacing={1}>
                          <LoadScript
                            googleMapsApiKey={
                              "AIzaSyCe_csGuwZcY7eUjCwwyoHzaVsduXt6pF0"
                            }
                            libraries={["places"]}
                            region="India"
                          >
                            <StandaloneSearchBox
                              onLoad={(ref) => (inputFromReff.current = ref)}
                              onPlacesChanged={handlePlaceChangedFrom}
                            >
                              <TextField
                                sx={{ input: { color: 'white' } }}
                                InputProps={{ style: { backgroundColor: "#212529" } }}
                                InputLabelProps={{ style: { color: "white" } }}
                                className={`mb-3`}
                                color="primary"
                                size="small"
                                label="From"
                                placeholder="" fullWidth />

                            </StandaloneSearchBox>
                            <StandaloneSearchBox
                              onLoad={(ref) => (inputToReff.current = ref)}
                              onPlacesChanged={handlePlaceChangedTo}
                            >
                              <TextField
                                sx={{ input: { color: 'white' } }}
                                InputProps={{ style: { backgroundColor: "#212529" } }}
                                InputLabelProps={{ style: { color: "white" } }}
                                className={`mb-3`}
                                color="primary"
                                size="small"
                                label="To"
                                placeholder="" fullWidth />
                            </StandaloneSearchBox>
                          </LoadScript>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </Stack>
              </Card.Body>
              <Card.Footer
                style={{
                  background: "#1a1e21",
                  display: props.data.owner ? "none" : "block",
                }}
              >
                <Stack
                  alignItems={"center"}
                  justifyContent="center"
                  direction={"row"}
                  gap={0.5}
                >
                  <Button className="btn-warning" onClick={handleShow} disabled={disableJoinBtn}>
                    Join
                  </Button>
                  {/* <OverlayTrigger show={disableTooltip} overlay={<Tooltip id="tooltip-disabled">Join the pool to send messages!</Tooltip>}>
                    <span className="d-inline-block">
                      <Button onClick={startConversation} className="btn-warning" disabled={dontAllowMessage} style={disableTooltip ? { pointerEvents: 'none' } : { "": "" }}>
                        Message
                      </Button>
                    </span>
                  </OverlayTrigger> */}
                </Stack>
              </Card.Footer>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default PoolDetailsContent;
