import FormLayout from "../../components/FormLayout";
import { Button, Form, Row, Col } from "react-bootstrap";
import styles from "../../styles/OverideStyles.module.css";
import { DatePicker } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { Box, FormControl, Stack, Typography, TextField, colors } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Modal from "react-bootstrap/Modal";
import { Select, MenuItem, InputLabel } from "@mui/material";
import dayjs from "dayjs";
import axios from "axios";
import request from "../../utils/request";
import Dropzone from "react-dropzone";
import { configuration } from "../../apis/config";
import { Link, useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { useRef } from "react";
import CardLayout from "../../components/CardLayout";
import { useMemo } from "react";
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes } from "firebase/storage";

function CreatePool() {
  const date = new Date();
  const nav = useNavigate();
  const [fromAddress, setFrom] = useState();
  const [toAddress, setTo] = useState();
  const [carFiles, setCarFiles] = useState([]);

  const [poolId, setPoolId] = useState();
  const inputFromRef = useRef();
  const inputToRef = useRef();
  const handlePlaceChangedFrom = async () => {
    const [place] = await inputFromRef.current.getPlaces();
    if (place) {
      console.log(place);
      setFrom(place);
    }
  };
  const firebaseConfig = {
    storageBucket: "gs://commute-92221.appspot.com",
  };
  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);

  function uploadImage(carId, img) {
    const storageRef = ref(storage, `${carId}.jpg`);
    uploadBytes(storageRef, img)
      .then((r) => {
        return r;
      })
      .catch((er) => {
        return er;
      });
  }
  const style = useMemo(() => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 10,
    borderColor: 'gray',
    borderStyle: 'dashed',
    backgroundColor: 'white',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  }), []);
  const handlePlaceChangedTo = () => {
    const [place] = inputToRef.current.getPlaces();
    if (place) {
      setTo(place);
    }
  };

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const initDate = dayjs(currentDate);
  const [datee, setDatee] = useState(initDate);
  const [time, setTime] = useState(dayjs("2018-01-01T00:00:00.000Z"));
  const [vehicles, setVehicles] = useState([]);
  const [age, setAge] = useState("");
  const [newCarAdded, setNewCarAdded] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const getToken = localStorage.getItem("token");
  useEffect(() => {
    axios({
      method: configuration.urls.vehiclesGetAllMethod,
      url: configuration.urls.vehiclesGetAll,
      headers: {
        Authorization: `Bearer ${getToken}`,
      },
    })
      .then((resp) => {
        setVehicles(resp.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }, [newCarAdded]);
  function sleep(milliseconds) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const d = new Date(String(datee.toDate()));
    const date = d.getDate();
    const month = d.getMonth() + 1;
    const year = d.getFullYear();

    const t = new Date(String(time.toDate()));
    const tim = t.toLocaleTimeString("en-US");
    const finalDate = `${date}/${month}/${year}`;
    const finalTime = String(tim);
    console.log(e.target.vehicle)
    const seats = String(e.target.seats.value);
    const vehicle = String(e.target.vehicle.value);
    const fare = String(e.target.fare.value);
    const pickup = String(e.target.pickup.value);
    axios({
      method: configuration.urls.createPoolMethod,
      url: configuration.urls.createPool,
      headers: {
        Authorization: `Bearer ${getToken}`,
      },
      data: {
        from: fromAddress.name,
        to: toAddress.name,
        seats: seats,
        vehicle: vehicle,
        fare: fare,
        pickup: pickup,
        starts: finalTime,
        date: finalDate,
        rawDate: datee,
        fromWholeAddress: fromAddress.formatted_address,
        toWholeAddress: toAddress.formatted_address,
      },
    })
      .then(async (resp) => {
        toast.success(resp.data.message);
        setPoolId(resp.data.data._id);
        await sleep(1500);
        nav(`/app/pools/${resp.data.data._id}`);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }
  const removeCarFile = (file) => () => {
    const newFiles = [...carFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setCarFiles(newFiles);
  };
  async function submitHandler(event) {
    event.preventDefault();
    const vName = String(event.target.vehicleName.value);
    const vNumber = String(event.target.vehicleNumber.value);
    const vColor = String(event.target.vehicleColor.value);
    const vType = String(event.target.vehicleType.value);
    const data = {
      name: vName,
      color: vColor,
      type: vType,
      number: vNumber,
    };

    try {
      const register = await request(configuration.urls.vehiclesRegisterMethod, configuration.urls.vehiclesRegister, data);
      if (register.status == true) {
        toast.success(register.message);
        uploadImage(register.data._id, carFiles[0]);
        setShow(false);
      }
    } catch (err) {
      if (err) {
        toast.error(err.response.data.message);
      }
    }
  }

  return (
    <><div style={{ height: "100%", width: "100%", alignContent: "center" }}>
      <CardLayout title={<Stack className="mb-3" direction={"row"} justifyContent={"space-between"}>
        <Typography className="mb-3" variant="h5" color={"white"} fontWeight={"bolder"}>
          Create Pool
        </Typography>
        <div>
          <Button size="sm" onClick={() => { setShow(true) }} style={{ backgroundColor: "#ffc107", borderColor: "#ffc107", color: "black" }}>Add Vehicle</Button>
        </div>
      </Stack>}
        submitHandler={handleSubmit}
        form={
          <>
            < LoadScript
              googleMapsApiKey={"AIzaSyCe_csGuwZcY7eUjCwwyoHzaVsduXt6pF0"}
              libraries={["places"]}
              region="India"
            >
              <StandaloneSearchBox
                onLoad={(ref) => (inputFromRef.current = ref)}
                onPlacesChanged={handlePlaceChangedFrom}
              >
                <Form.Group className="mb-3" controlId="formBasicFrom">
                  <Form.Control
                    size="small"
                    name="from"
                    color="warning"
                    id="formBasicFrom"
                    placeholder="From"
                    variant="outlined"
                    className={styles.formfield}
                    fullWidth
                  />
                </Form.Group>
              </StandaloneSearchBox>
              <StandaloneSearchBox
                onLoad={(ref) => (inputToRef.current = ref)}
                onPlacesChanged={handlePlaceChangedTo}
              >
                <Form.Group className="mb-3" controlId="formBasicTo">
                  <Form.Control
                    size="small"
                    className={styles.formfield}
                    name="to"
                    id="formBasicTo"
                    placeholder="To"
                    variant="outlined"
                    fullWidth
                  />
                </Form.Group>
              </StandaloneSearchBox>
            </LoadScript>
            <Form.Group className="mb-3" controlId="formBasicSeats">
              <Form.Control
                size="small"
                className={styles.formfield}
                name="seats"
                id="formBasicSeats"
                placeholder="Seats available"
                variant="outlined"
                fullWidth
              />
            </Form.Group>
            <Stack direction={"horizontal"}>
              <Form.Group className="mb-3" controlId="formBasicStart">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    value={time}
                    className={`${styles.formfield} rounded`}
                    slotProps={{ textField: { size: "small" } }}
                    label="Start's At"
                    onChange={(newValue) => {
                      setTime(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField className={"text-white"} size="small" {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicDate">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className={`${styles.formfield} rounded`}
                    slotProps={{ textField: { size: "small" } }}
                    value={datee}
                    label="Date"
                    onChange={(newValue) => {
                      setDatee(newValue);
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField className={"text-white"} {...params} size="small" />
                      )
                    }}
                  />
                </LocalizationProvider>
              </Form.Group>
            </Stack>
            <Stack direction={"horizontal"}>
              <Form.Group className="mb-3" controlId="formBasicVehicle">
                <Form.Select className={styles.formfield} name="vehicle">
                  <option>Vehicle</option>
                  {vehicles.map((e, index) => {
                    return <option value={e._id} key={index}>{e.name}</option>;
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Control
                size="small"
                style={{ height: "fit-content" }}
                className={styles.formfield}
                name="fare"
                id="formBasicFare"
                placeholder="Fare"
                variant="outlined"
                fullWidth
              />
            </Stack>
            <Form.Group className="mb-3" controlId="formBasicPick">
              <Form.Control
                size="small"
                className={styles.formfield}
                name="pickup"
                id="formBasicPick"
                placeholder="Pickup At"
                variant="outlined"
                fullWidth
              />
            </Form.Group>
            <Form.Group className="mb-3 text-center" controlId="formBasicFare">
              <Button type="submit" className="btn-warning">
                Create
              </Button>
            </Form.Group>
            {/* </Form> */}
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Add Vehicle</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form
                  className="mb-3"
                  onSubmit={submitHandler}
                  encType="multipart/form-data"
                >

                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="vehicleName">
                      <Form.Control
                        size="sm"
                        name="vehicleName"
                        type="text"
                        placeholder="Enter your vehicle name"
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="vehicleType">
                      <Form.Select
                        aria-label="Default select example"
                        size="sm"
                        name="vehicleType"
                        required
                      >
                        {/* <option>Vehicle Type</option> */}
                        <option value="Car">Car</option>
                        <option value="Bike">Bike</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="vehicleColor">
                      <Form.Control
                        size="sm"
                        name="vehicleColor"
                        type="text"
                        placeholder="Enter your vehicle color"
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="vehicleNumber">
                      <Form.Control
                        size="sm"
                        name="vehicleNumber"
                        type="text"
                        placeholder="Enter your vehicle number"
                      />
                    </Form.Group>
                  </Row>
                  {/* Below is for vehicles */}
                  <Form.Group>
                    {carFiles.length == 0 && (
                      <Dropzone
                        multiple={false}
                        onDrop={(acceptedFiles) => {
                          setCarFiles(acceptedFiles);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section
                            className="container"
                            style={{ paddingBottom: "10px" }}
                          >
                            <div
                              {...getRootProps({ style })}
                            >
                              <input {...getInputProps()} />
                              <p style={{ marginBottom: "0px" }}>
                                Select car image
                              </p>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    )}
                  </Form.Group>
                  {carFiles.map((file) => (
                    <li style={{ padding: "5px" }} key={file.path}>
                      {file.name}{" "}
                      <button
                        className="btn btn-warning"
                        onClick={removeCarFile(file)}
                      >
                        X
                      </button>
                    </li>
                  ))}
                  <div className="d-grid gap-2">
                    <Button
                      type="submit"
                      size="sm"
                      className="btn btn-warning"
                      style={{ fontWeight: "bold" }}
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              </Modal.Body>
            </Modal>
          </>} />
    </div>
    </>
  );
}

export default CreatePool;
