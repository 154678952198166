import { Card } from "react-bootstrap";
import { Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import styles from "../styles/OverideStyles.module.css"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
function PoolCard(props) {
    const arrowRight = <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
    </svg>;
    return (<>
        <Card className="shadow-lg" style={{ borderColor: "#212529" }}>
            <Card.Header className="bg-warning">
                <div className="row text-center">
                    <div className="col-5">
                        <Typography variant="body2" className="text-muted m-0">From</Typography>
                        <Typography variant="body1" fontWeight={"bold"}>{props.data.from}</Typography>
                    </div>
                    <div className="col-2">
                        <br />
                        <Tooltip title="Via kothur, palmakula, shamshabad" arrow>
                            {arrowRight}
                        </Tooltip>
                    </div>
                    <div className="col-5">
                        <Typography variant="body2" className="text-muted m-0">To</Typography>
                        <Typography variant="body1" fontWeight={"bold"}>{props.data.to}</Typography>
                    </div>
                </div>
            </Card.Header>
            <Card.Footer style={{ backgroundColor: "#212529" }}>
                <Grid2 container className="text-center" spacing={2}>
                    <Grid2 xs={2.5} sm={2.5} md={2.5} lg={2.5} xl={2.5}>
                        <Typography variant="body2" className="text-muted">Seats Available</Typography>
                        <Typography variant="body2" className="text-white">{props.data.seats}</Typography>
                    </Grid2>
                    <Grid2 xs={2.5} sm={2.5} md={2.5} lg={2.5} xl={2.5} className={` ${styles.vertLine}`}>
                        <Typography variant="body2" className="text-muted">Starts At</Typography>
                        <Typography variant="body2" className="text-white">{props.data.starts}</Typography>
                    </Grid2>
                    <Grid2 xs={2.5} sm={2.5} md={2.5} lg={2.5} xl={2.5} className={`${styles.vertLineRight}`}>
                        <Typography variant="body2" className="text-muted">Fare</Typography>
                        <Typography variant="body2" className="text-white">{props.data.fare}₹</Typography>
                    </Grid2>
                    {/* <div className={`col-sm-4 col-md-2.5 col-lg-2.5 col-xl-2.5 ${styles.vertLineRight}`}>
                        <Typography variant="body2" className="text-muted">vehicle</Typography>
                        <Typography variant="body2" className="text-white">{props.data.vehicle.name}</Typography>
                    </div> */}
                    <Grid2 xs={2.5} sm={2.5} md={2.5} lg={2.5} xl={2.5} className={`${styles.vertLineRight}`}>
                        <Typography variant="body2" className="text-muted">Date</Typography>
                        <Typography variant="body2" className="text-white">{props.data.date}</Typography>
                    </Grid2>
                    <Grid2 xs={2} sm={2} md={2} lg={2} xl={2}>
                        <Link to={`/app/pools/${props.data._id}`} className="btn btn-warning btn-sm">Details</Link>
                    </Grid2>
                </Grid2>
            </Card.Footer>
        </Card>
    </>);
}

export default PoolCard;