import { Alert } from "react-bootstrap";
import styles from "../styles/OverideStyles.module.css";

function InvalidLink(props) {
    return (
        <>
            <div className={`${styles.mbody}`}>
                <div className={`${styles.containerlogin}`}>
                    <Alert variant={props.color ? props.color : "danger"}>
                        {props.message}
                    </Alert>
                </div>
            </div>
        </>
    );
}

export default InvalidLink;