import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { configuration } from "../../apis/config";
import { memo } from "react";

export const getUserDetails = createAsyncThunk(
    'user/details',
    async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const getToken = localStorage.getItem("token");
                const userDetails = await axios({
                    method: configuration.urls.accountMethod,
                    url: configuration.urls.account,
                    headers: {
                        "Authorization": `Bearer ${getToken}`
                    }
                });
                console.log(userDetails.data.data)
                resolve(userDetails.data.data);
            }
            catch (err) {
                console.log(err);
            }
        });
    }
);

const user = createSlice({
    name: "user",
    initialState: {
        userDetails: false
    },
    extraReducers: (builder) => {
        builder.addCase(getUserDetails.fulfilled, (state, action) => {
            state.userDetails = action.payload;
        })
    }
});

export default user.reducer;