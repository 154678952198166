
import NotificationsLayout from "../../components/NotificationsLayout";
import { Stack } from "react-bootstrap";
import { Alert } from "@mui/material";
import socketIOClient from "socket.io-client";
import config from "../../config";
import { configuration } from "../../apis/config";
import { useEffect, useState } from "react";
import axios from "axios";
import { Typography } from "@mui/material";
import { store } from "../../store/store";
import Loader from "../../components/Loader";

function Notifications() {
    const [notifications, setNotifications] = useState([]);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        setNotifications(store.getState().notifications.notifications);
        setIsReady(true);
    }, [])

    if (isReady) {
        return (<>
            <div style={{ height: "100%", width: "100%", alignContent: "center" }}>
                <div
                    className="p-3 shadow-lg m-auto"
                    style={{
                        width: "auto",
                        minWidth: "250px",
                        maxWidth: "400px",
                        backgroundColor: "#111315",
                        borderRadius: "10px",
                    }}
                >
                    <Typography className="mb-3" variant="h5" color={"white"} fontWeight={"bolder"}>Notifications</Typography>
                    <Stack gap={2}>
                        {notifications.length == 0 &&
                            <Alert style={{ fontWeight: "bold" }} variant="filled" severity="warning" >No notifications yet!</Alert>
                        }
                        {notifications.length > 0 && notifications.map((item, index) => {
                            console.log(item)
                            const bgColor =
                                item.event == "joinRequest"
                                    ? { color: "#FFD966", severity: "warning" }
                                    : item.event == "acceptRequest"
                                        ? { color: "#CBFFA9", severity: "success" }
                                        : item.event == "rejectRequest"
                                            ? { color: "#FF6969", severity: "error" }
                                            : item.event == "cancelledBRequest"
                                                ? { color: "#FF9B9B", severity: "error" }
                                                : item.event == "cancelledARequest"
                                                    ? { color: "#FF9B9B", severity: "error" }
                                                    : item.event == "customRequest"
                                                        ? { color: "#78C1F3", severity: "info" }
                                                        : item.event == "joinOnTheWayRequest"
                                                            ? { color: "#ffc107", severity: "warning" }
                                                            : { color: "white", severity: "warning" };
                            return <Alert severity={bgColor.severity}>{item.content}</Alert>
                        })
                        }
                    </Stack>
                </div>
            </div>
        </>);
    }
    else {
        return <Loader />
    }
}
export default Notifications;


// "eslintConfig": {"extends": ["react-app","react-app/jest"]},