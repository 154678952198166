import style from "../../styles/OverideStyles.module.css";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Button,
  ButtonGroup,
  Card,
  Modal,
  Alert,
  Spinner,
} from "react-bootstrap";
import { TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import { configuration } from "../../apis/config";
import { useParams } from "react-router-dom";
import styles from "../../styles/OverideStyles.module.css"
import { Toaster, toast } from "react-hot-toast";
import PoolDetailsContent from "../../components/PoolDetailsContent";
import request from "../../utils/request";
function PoolDetails() {
  const { poolId } = useParams();
  const [pool, setPool] = useState();
  const [tempFare, setTempFare] = useState(0);
  const [dta, setDta] = useState();
  const [triggerUseEffect, setTriggerUseEffect] = useState();
  const [touchUseEffect, setTouchUseEffect] = useState();
  const [active, setActive] = useState(false);
  const getToken = localStorage.getItem("token");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    axios({
      method: configuration.urls.getPoolsMethod,
      url: configuration.urls.getPools,
      headers: {
        Authorization: `Bearer ${getToken}`,
      },
      data: {
        poolId: poolId,
      },
    })
      .then((resp) => {
        console.log(resp.data.data)
        setPool(resp.data.data);
        setActive(resp.data.isActive);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }, [touchUseEffect, triggerUseEffect]);

  function decisionHandler(e) {
    const poolId = e.target.attributes.getNamedItem("request").value;
    const ctm = e.target.attributes.getNamedItem("data-custom").value;
    const decision = e.target.name;
    const reqId = e.target.id;
    request("POST", configuration.urls.decidePool, {
      decision: decision,
      reqId: reqId,
      poolId: poolId,
      fare: tempFare ? Number(tempFare) : 0,
      isCustom: ctm,
    },)
      .then((resp) => {
        toast.success(resp.data.message);
        setDta(resp.data);
        setTouchUseEffect(resp.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  }

  function handlePayment(e) {
    request("POST", configuration.urls.payment, { poolId: pool._id })
      .then((resp) => {
        var options = {
          key: "rzp_test_EVd4ty4c6hUu4b", // Enter the Key ID generated from the Dashboard
          amount: resp.data.amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: "INR",
          name: "Commute", //your business name
          description: "Payment",
          image:
            "https://www.adobe.com/content/dam/cc/us/en/creativecloud/design/discover/mascot-logo-design/mascot-logo-design_fb-img_1200x800.jpg",
          order_id: resp.data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          callback_url: "http://localhost:3002/payments/listener",
          prefill: {
            name: "Preetham", //your customer's name
            email: "preetham.interakt@gmail.com",
            contact: "8985525001",
          },
          notes: {
            address: "Commute corporate gachibowli",
          },
          theme: {
            color: "#ffc107",
          },
        };
        var rzp1 = new Razorpay(options);
        rzp1.open();
        e.preventDefault();

      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleJoinFeedback(data) {
    setTriggerUseEffect(data)
  }
  if (pool) {
    return (
      <div style={{}}>
        <PoolDetailsContent data={pool} poolid={poolId} isUserActive={active} joinFeedback={handleJoinFeedback} />
        <div className="container" style={{ padding: "10px" }}>
          <Accordion sx={{ backgroundColor: "#1a1e21", color: "white" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >Request's</AccordionSummary>
            <AccordionDetails className={`text-center p-4`} sx={{ backgroundColor: "#1a1e21" }}>
              {!pool.owner && pool.requests.length > 0 &&
                pool.requests.map((p) => {
                  return (
                    <>
                      <Stack direction="row" spacing={0.5}>
                        {p.status == "dis" ? (
                          <p style={{ marginTop: "auto", marginBottom: "auto" }}>
                            Commander responded with fare{" "}
                            <span style={{ color: "green" }}>
                              {p.fare}₹
                            </span>{" "}
                            to commute from {p.from} to {p.to}
                          </p>
                        ) : (
                          <p style={{ marginTop: "auto", marginBottom: "auto" }}>
                            Your request to commute from {p.from} to{" "}
                            {p.to} is <b>{p.status}.</b>
                          </p>
                        )}
                        {p.status !== "cancelled" &&
                          p.status !== "dis" &&
                          p.status !== "rejected" && (
                            <Button
                              variant="danger"
                              onClick={decisionHandler}
                              name="cancel"
                              id={p._id}
                              request={p.pool}
                              data-custom={p.isCustom}
                              disabled={p.paid}
                            >
                              Cancel
                            </Button>
                          )}
                        {p.status !== "cancelled" &&
                          p.status !== "rejected" &&
                          p.status == "dis" && (
                            <>
                              <Button
                                variant="warning"
                                onClick={decisionHandler}
                                name="accept"
                                id={p._id}
                                request={p.pool}
                                data-custom={p.isCustom}
                              >
                                Accept
                              </Button>
                              <Button
                                variant="danger"
                                onClick={decisionHandler}
                                name="cancel"
                                id={p._id}
                                request={p.pool}
                                data-custom={p.isCustom}
                              >
                                Cancel
                              </Button>
                            </>
                          )}
                        {p.status == "accepted" && p.paid == false && (
                          <Button
                            variant="warning"
                            onClick={handlePayment}
                          >
                            Pay
                          </Button>
                        )}
                      </Stack>
                    </>
                  );
                })}
              {!pool.owner && pool.requests.length <= 0 &&
                <p>You need to join this Pool to see your request details.</p>
              }
              {pool.owner &&
                pool.requests.map((p) => {
                  return (
                    <>
                      <Stack direction={"row"} spacing={1}>
                        <p style={{}}>
                          <span style={{ fontWeight: "bold", color: "#ffc107" }}>{p.userId.name}</span> requested to join from {p.from} to {p.to} is{" - "}
                          <b>
                            {p.status == "dis" ? "Pending" : p.status}.
                          </b>
                        </p>

                        {p.isCustom == true && (
                          <>
                            <Modal
                              show={show}
                              onHide={handleClose}
                              centered
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>Confirm</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <Stack>
                                  <Table
                                    borderless
                                    style={{ margin: "auto" }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          className={`${style.limittd}`}
                                        >
                                          <h5>From:</h5>
                                        </td>
                                        <td>
                                          <p>{p.from}</p>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          className={`${style.limittd}`}
                                        >
                                          <h5>To:</h5>
                                        </td>
                                        <td>
                                          <p>{p.to}</p>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          className={`${style.limittd}`}
                                        >
                                          <h5>Seats:</h5>
                                        </td>
                                        <td>
                                          <p>{p.seats}</p>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          className={`${style.limittd}`}
                                        >
                                          <h5>Fare:</h5>
                                        </td>
                                        <td>
                                          <TextField
                                            label="Charge"
                                            placeholder="Enter charge for each seat"
                                            onChange={(e) => {
                                              setTempFare(
                                                e.target.value
                                              );
                                            }}
                                            fullWidth
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          className={`${style.limittd}`}
                                        >
                                          <h5>Total fare:</h5>
                                        </td>
                                        <td>
                                          <p style={{ color: "green" }}>
                                            {tempFare * p.seats}₹
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </Stack>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="dark"
                                  onClick={handleClose}
                                >
                                  Close
                                </Button>
                                <Button
                                  variant="warning"
                                  request={p.pool}
                                  data-custom={p.isCustom}
                                  id={p._id}
                                  name="dis"
                                  onClick={decisionHandler}
                                >
                                  Confirm
                                </Button>
                              </Modal.Footer>
                            </Modal>
                            {p.status == "pending" && (
                              <Button
                                variant="warning"
                                onClick={handleShow}
                              >
                                Quote
                              </Button>
                            )}
                            {p.status == "pending" && (
                              <Button
                                variant="danger"
                                data-custom={p.isCustom}
                                request={p.pool}
                                name="reject"
                                id={p._id}
                                onClick={decisionHandler}
                              >
                                Reject
                              </Button>
                            )}
                          </>
                        )}
                        {!p.isCustom && (
                          <>
                            {p.status == "pending" && (
                              <Stack direction={"row"} display={"inline"} spacing={1}>
                                <Button
                                  size="sm"
                                  variant="warning"
                                  data-custom={p.isCustom}
                                  request={p.pool}
                                  name="accept"
                                  id={p._id}
                                  onClick={decisionHandler}
                                >
                                  Accept
                                </Button>
                                <Button
                                  size="sm"
                                  variant="danger"
                                  data-custom={p.isCustom}
                                  request={p.pool}
                                  name="reject"
                                  id={p._id}
                                  onClick={decisionHandler}
                                >
                                  Reject
                                </Button>
                              </Stack>
                            )}
                          </>
                        )}
                      </Stack>
                    </>
                  );
                })}
              {pool.owner &&
                pool.requests.length == 0 &&
                <Alert style={{ display: "inline" }} variant="warning">No requests yet!</Alert>
              }
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    );
  }
  else {
    return <Spinner />
  }
}

export default PoolDetails;
