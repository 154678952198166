import * as React from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link, Navigate, NavLink, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import logo from "../images/icon.png";
import { configuration } from "../apis/config";
import { socket } from "../socket";
import LogoutIcon from '@mui/icons-material/Logout';

import HailIcon from "@mui/icons-material/Hail";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import WalletIcon from "@mui/icons-material/Wallet";
import NotificationsIcon from "@mui/icons-material/Notifications";

import HistoryIcon from "@mui/icons-material/History";
import MenuIcon from "@mui/icons-material/Menu";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { Typography } from "@mui/material";
import { Button } from "react-bootstrap";
import request from "../utils/request";
import { setNotifications } from "../store/features/notifications.store";
import { useDispatch } from "react-redux";

function SideBar(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  isAuthenticated();

  React.useEffect(() => {
    fetchAndSetNotifications();
  }, []);

  React.useEffect(() => {
    socket.on("updateNotifications", (data) => {
      console.log("New notification: ", data)
    })
  }, [])

  const [toggled, setToggled] = React.useState(false);
  const [broken, setBroken] = React.useState(
    window.matchMedia("(max-width: 800px)").matches
  );

  function isAuthenticated() {
    const isAuthenticated = localStorage.getItem("token");
    if (isAuthenticated !== null) {
      return true;
    }
    else {
      navigate("/login");
    }
  }

  async function logoutHandler() {
    const getToken = localStorage.getItem("token");
    if (getToken !== null) {
      localStorage.removeItem("token");
      navigate("/login");
    } else {
      throw new Error("Invalid");
    }
  }

  function fetchAndSetNotifications() {
    request("POST", configuration.urls.getNotifications)
      .then((response) => {
        dispatch(setNotifications({ notifications: response.data }));
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const menuItems = [
    {
      name: "Pools",
      link: "pools",
      active: true,
      icon: <HailIcon style={{ color: "#ffc107" }} />,
    },
    {
      name: "Commander",
      link: "commander",
      icon: <LocalPoliceIcon style={{ color: "#ffc107" }} />,
    },
    {
      name: "Wallet",
      link: "wallet",
      icon: <WalletIcon style={{ color: "#ffc107" }} />,
    },
    {
      name: "Notifications",
      link: "notifications",
      icon: <NotificationsIcon style={{ color: "#ffc107" }} />,
    },
    {
      name: "History",
      link: "history",
      icon: <HistoryIcon style={{ color: "#ffc107" }} />,
    },
    {
      name: "Support",
      link: "support",
      icon: <SupportAgentIcon style={{ color: "#ffc107" }} />,
    },
    {
      name: "Account",
      link: "account",
      icon: <ManageAccountsIcon style={{ color: "#ffc107" }} />,
    },
  ];
  return (
    <div style={isAuthenticated ? { display: "flex", height: "auto", minHeight: "100vh" } : { display: "flex", height: "100vh", minHeight: "400px" }}>
      <Sidebar
        onBackdropClick={() => setToggled(false)}
        toggled={toggled}
        customBreakPoint="800px"
        onBreakPoint={setBroken}
        backgroundColor="#212529"
        style={{ borderRightStyle: "none" }}
        className="shadow-lg"
      >
        <div className="p-2 text-center">
          <img
            src={logo}
            width={"45px"}
            height={"45px"}
            style={{ borderRadius: "10px" }}
          />
        </div>
        <div>
          <Menu
            menuItemStyles={{
              button: {
                // the active class will be added automatically by react router
                // so we can use it to style the active menu item
                [`&.active`]: {
                  backgroundColor: "rgba(255, 193, 7,0.3)",
                  color: "#ffc107",
                  fontWeight: "bold"
                },
                [`&:hover`]: {
                  backgroundColor: "rgba(255, 193, 7,0.3)",
                  color: "#ffc107",
                  fontWeight: "bold"
                },
                color: "gray",
              },
            }}
          >
            {menuItems.map((item, index) => {
              return (
                <MenuItem
                  component={<NavLink to={item.link} className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  } />}
                  active={item.active ? true : false}
                  key={index}
                  icon={item.icon}
                >
                  {item.name}
                </MenuItem>
              );
            })}
          </Menu>
        </div>
        <div className="mt-2" style={{ textAlign: "center", alignSelf: "end" }}>
          <Button onClick={logoutHandler} size="sm" style={{ backgroundColor: "#ffc107", color: "black", borderColor: "#ffc107" }}><LogoutIcon fontSize="30px" /> Logout</Button>
        </div>
      </Sidebar>
      <main style={{ padding: 10, backgroundColor: "#212529", width: "100%" }}>
        <div>
          {broken && (
            <MenuIcon
              style={{ color: "#ffc107", cursor: "pointer" }}
              onClick={() => setToggled(!toggled)}
            />
          )}
        </div>
        <Outlet />
      </main>
    </div>
  );
}

export default SideBar;
